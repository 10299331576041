// actions
import { STORE_DATA, NEXT_STEP, PREV_STEP } from '../actions/questionnaire';

const INITIAL_STATE = {
  activeStepIndex: 0,
};

export default (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case STORE_DATA: {
      return {
        ...state,
        listingType: actions.listingType,
        filters: actions.data,
      };
    }

    case NEXT_STEP: {
      return {
        ...state,
        activeStepIndex: state.activeStepIndex + 1,
      };
    }

    case PREV_STEP: {
      return {
        ...state,
        activeStepIndex: state.activeStepIndex - 1,
      };
    }

    default:
      return state;
  }
};
