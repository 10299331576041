import React, { useContext, useState, useRef, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Providers
import { I18nContext } from '../providers/I18nContextProvider';

// Utils
import { formatNumber } from '../utils/formatNumber';

// Styles
import textStyles from '../styles/textStyles';
import colors from '../styles/colors';
import mediaQuery from '../styles/breakpoints';
import sizes from '../styles/sizes';

// Components
import Container from './layouts/Container';
import Shape from './assets/Shape';

const Restyled = {
  Shape: styled(Shape)`
    z-index: 1;
    position: absolute;
    bottom: -20%;
    left: -20%;
    width: 60%;
  `,
};

const Styled = {
  Title: styled.h2`
    margin-bottom: ${sizes.space * 3}px;
    text-align: center;

    ${mediaQuery.from.S`
      margin-bottom: ${sizes.space * 5}px;
    `}
  `,

  Content: styled.div`
    ${mediaQuery.from.S`
      display: flex;
    `}
  `,

  ImageWrapper: styled.div`
    position: relative;
    margin-bottom: ${sizes.space * 3}px;
    border-radius: ${sizes.borderRadiusImage}px;
    overflow: hidden;
    background-color: ${colors.grey};

    ${mediaQuery.from.S`
      min-width: 50%;
      margin-right: ${sizes.space * 4}px;
      margin-bottom: 0;
    `}

    ${mediaQuery.from.M`
      margin-right: ${sizes.space * 10}px;
    `}

    ${mediaQuery.from.L`
      min-width: 40%;
      width: 40%;
      margin-right: ${sizes.space * 14}px;
    `}
  `,

  TextWrapper: styled.div`
    display: flex;
    flex-direction: column;

    ${mediaQuery.from.S`
      margin: auto 0;
    `}
  `,

  Number: styled.span`
    ${textStyles.screamingHighlight};
    display: block;
    color: ${colors.primary};
  `,

  Label: styled.span`
    ${textStyles.bodyLarge};
    display: block;
    margin-bottom: ${sizes.space * 3}px;

    ${mediaQuery.from.S`
      margin-bottom: ${sizes.space * 2}px;
    `}

    ${mediaQuery.from.M`
      margin-bottom: ${sizes.space * 5}px;
    `}
  `,

  Subtitle: styled.h3`
    ${textStyles.titleNormal};
    max-width: 750px;
  `,
};

const Stats = ({ title, image, stats, subtitle }) => {
  const { translate } = useContext(I18nContext);

  const [shown, setShown] = useState(false);
  const contentRef = useRef(null);

  const counter = (id, start, end, duration) => {
    let startTimestamp = null;

    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;

      const progress = Math.min((timestamp - startTimestamp) / duration, 1);

      const element = document.getElementById(id);
      element.innerHTML = formatNumber(Math.floor(progress * (end - start) + start));
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  useEffect(() => {
    if (shown && stats) {
      counter('total-purchase-stat', 10000, stats.total_purchase, 1500);
      counter('total-rental-stat', 10000, stats.total_rental, 2000);
    }
  }, [shown, stats]);

  useLayoutEffect(() => {
    const topPos = (element) => element.getBoundingClientRect().top;
    const contentPosition = topPos(contentRef.current);

    const onScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      setShown(contentPosition <= scrollPosition);
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <Container>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Content ref={contentRef}>
        <Styled.ImageWrapper>
          <Restyled.Shape />
          <img src={image.url} alt={image.alt} />
        </Styled.ImageWrapper>
        <Styled.TextWrapper>
          <div>
            <Styled.Number id="total-purchase-stat">
              {stats ? formatNumber(stats.total_purchase) : 0}
            </Styled.Number>
            <Styled.Label>{translate('app.home.stats.buyLabel')}</Styled.Label>
          </div>
          <div>
            <Styled.Number id="total-rental-stat">
              {stats ? formatNumber(stats.total_rental) : 0}
            </Styled.Number>
            <Styled.Label>{translate('app.home.stats.rentLabel')}</Styled.Label>
          </div>
          <Styled.Subtitle>{subtitle}</Styled.Subtitle>
        </Styled.TextWrapper>
      </Styled.Content>
    </Container>
  );
};

Stats.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
    dimensions: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }).isRequired,
  stats: PropTypes.shape({
    total_purchase: PropTypes.number,
    total_rental: PropTypes.number,
  }),
  subtitle: PropTypes.string.isRequired,
};

Stats.defaultProps = {
  stats: {},
};

export default Stats;
