export const LISTING_FETCH = 'LISTING_FETCH';
export const LISTING_FETCH_SUCCESS = 'LISTING_FETCH_SUCCESS';
export const LISTING_FETCH_FAIL = 'LISTING_FETCH_FAIL';

export function fetchListing(id) {
  return {
    type: LISTING_FETCH,
    payload: {
      request: {
        url: `/properties/${id}`,
        method: 'GET',
      },
    },
  };
}
