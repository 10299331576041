export default {
  white: '#FFFFFF',
  grey: '#999999',
  black: '#000000',

  primary: '#53D28D',
  primaryLight: '#E6FBEF',
  secondary: '#FE8F50',

  error: '#E71F1F',
  warning: '#E56821',
  valid: '#53D28D',
};
