import React from 'react';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';

const Filter = ({ width, className }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 40 40"
    className={className}
  >
    <path
      d="M13.332 5h21.667v3.333H13.332V5zM3.332 18.334h21.667v3.333H3.332v-3.333z"
      fill={colors.black}
    />
    <path
      d="M16.665 6.667a6.667 6.667 0 11-13.333 0 6.667 6.667 0 0113.333 0z"
      fill={colors.primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.999 10a3.333 3.333 0 100-6.667 3.333 3.333 0 000 6.667zm0 3.333A6.667 6.667 0 109.999 0a6.667 6.667 0 000 13.333z"
      fill={colors.black}
    />
    <path
      d="M16.665 33.333a6.667 6.667 0 11-13.333 0 6.667 6.667 0 0113.333 0z"
      fill={colors.primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.999 36.666a3.333 3.333 0 100-6.667 3.333 3.333 0 000 6.667zm0 3.333a6.667 6.667 0 100-13.333 6.667 6.667 0 000 13.333z"
      fill={colors.black}
    />
    <path
      d="M36.665 20a6.667 6.667 0 11-13.333 0 6.667 6.667 0 0113.333 0z"
      fill={colors.primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.999 23.334a3.333 3.333 0 100-6.667 3.333 3.333 0 000 6.667zm0 3.333a6.667 6.667 0 100-13.333 6.667 6.667 0 000 13.333z"
      fill={colors.black}
    />
    <path d="M13.332 31.666h21.667v3.333H13.332v-3.333z" fill={colors.black} />
  </svg>
);

Filter.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

export default Filter;
