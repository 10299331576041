import React, { useEffect, useState, useRef, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

// Providers
import { I18nContext } from '../providers/I18nContextProvider';

// Styles
import colors from '../styles/colors';
import mediaQuery from '../styles/breakpoints';
import textStyles from '../styles/textStyles';
import sizes from '../styles/sizes';

// Components
import Map from './assets/Map';
import Icon from './icons/Icon';
import GoogleMaps from './GoogleMaps';

const StyledMap = styled(Map)`
  width: 80px;
  height: 80px;
  transition: transform 0.2s ease-in-out;
`;

const Styled = {
  Container: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${sizes.space}px;
    background-color: ${colors.primaryLight};
    border-radius: ${sizes.borderRadius}px;

    ${mediaQuery.from.XS`
      padding: 8px 8px 8px 16px;
    `}

    ${mediaQuery.from.S`
      padding: 16px 16px 16px 56px;
    `}
  `,
  Content: styled.div`
    ${textStyles.titleSubtle};
    display: flex;
  `,
  Item: styled.div`
    display: flex;
    align-items: center;
    margin-right: ${sizes.space * 2}px;

    ${mediaQuery.from.XS`
      margin-right: ${sizes.space * 7}px;
    `};

    > span {
      ${mediaQuery.to.M`
        display: none;
      `}
    }
  `,
  Icon: styled(Icon)`
    margin-right: ${sizes.space}px;

    ${mediaQuery.from.S`
      margin-right: ${sizes.space * 2}px;
    `}
  `,
  MapHolder: styled.div`
    cursor: pointer;
    overflow: hidden;
    display: flex;
    border-radius: ${sizes.borderRadius}px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);

    :hover {
      ${StyledMap} {
        transform: scale(1.2);
      }
    }
  `,
  Modal: styled.dialog`
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 64px 16px 16px;
    background-color: ${colors.white};
    border: none;
    display: ${({ open }) => (open ? 'flex' : 'none')};

    ${mediaQuery.from.S`
      padding: 80px 32px 32px;
    `}
  `,
  Close: styled.button`
    cursor: pointer;
    position: absolute;
    top: ${sizes.space * 2}px;
    right: ${sizes.space * 2}px;
    display: flex;
    padding: 0;
    background: transparent;
    border: 0;
    outline: none;
    transition: transform 0.08s ease-in-out;

    ${mediaQuery.from.S`
      top: 32px;
      right: 32px;
    `}

    :hover {
      transform: scale(1.2);
    }
  `,
  GoogleMaps: styled(GoogleMaps)`
    height: calc(100vh - 80px);

    ${mediaQuery.from.S`
        height: calc(100vh - 112px);
    `}
  `,
};

const ListingFeatureCard = ({ size, rooms, bedrooms, location, className }) => {
  const { translate } = useContext(I18nContext);
  const modalRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (openModal) {
      disableBodyScroll(modalRef?.current);
    } else {
      enableBodyScroll(modalRef?.current);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [openModal]);

  return (
    <>
      <Styled.Container className={className}>
        <Styled.Content>
          <Styled.Item>
            <Styled.Icon icon="surface" width={32} />
            {size}m2 &nbsp;
            <span>{translate('app.listing.details.surface.size').toLowerCase()}</span>
          </Styled.Item>
          <Styled.Item>
            <Styled.Icon icon="door" width={32} />
            {rooms} &nbsp;
            <span>{translate('app.listing.details.layout.rooms').toLowerCase()}</span>
          </Styled.Item>
          <Styled.Item>
            <Styled.Icon icon="bed" width={32} />
            {bedrooms} &nbsp;
            <span>{translate('app.listing.details.layout.bedrooms').toLowerCase()}</span>
          </Styled.Item>
        </Styled.Content>
        {location.lat !== 0 && location.lng !== 0 && (
          <Styled.MapHolder>
            <StyledMap onClick={() => setOpenModal(true)} />
          </Styled.MapHolder>
        )}
      </Styled.Container>

      {location.lat !== 0 && location.lng !== 0 && (
        <Styled.Modal open={openModal} ref={modalRef}>
          <Styled.Close type="button" onClick={() => setOpenModal(false)}>
            <Icon icon="cross" width={32} color={colors.black} />
          </Styled.Close>
          <Styled.GoogleMaps location={location} />
        </Styled.Modal>
      )}
    </>
  );
};

ListingFeatureCard.propTypes = {
  size: PropTypes.number.isRequired,
  rooms: PropTypes.number.isRequired,
  bedrooms: PropTypes.number.isRequired,
  location: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  className: PropTypes.string.isRequired,
};

export default ListingFeatureCard;
