import React from 'react';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';

const NewMail = ({ width, className }) => (
  <svg
    className={className}
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 160 160"
    fill="none"
  >
    <path d="M26.667 40H160v106.667H26.667V40z" fill={colors.white} />
    <path d="M93.333 103.333L150 50v-3.333H30l63.333 56.666z" fill={colors.primary} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M146.667 53.333H40v80h106.667v-80zM26.667 40v106.667H160V40H26.667z"
      fill={colors.black}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.906 54.985l8.858-9.966 55.571 49.396 55.571-49.396 8.858 9.966-64.429 57.27-64.429-57.27z"
      fill={colors.black}
    />
    <path
      d="M80 46.667c0 22.091-17.909 40-40 40s-40-17.909-40-40c0-22.092 17.909-40 40-40s40 17.908 40 40z"
      fill="#FE8F50"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40 73.333c14.728 0 26.667-11.939 26.667-26.666C66.667 31.939 54.727 20 40 20c-14.728 0-26.667 11.939-26.667 26.667 0 14.727 11.94 26.666 26.667 26.666zm0 13.334c22.091 0 40-17.909 40-40 0-22.092-17.909-40-40-40s-40 17.908-40 40c0 22.091 17.909 40 40 40z"
      fill={colors.black}
    />
    <path
      d="M35.223 30l-8.556 4.686V49.42l7.991-4.3v18.213h12.009V30H35.223z"
      fill={colors.black}
    />
  </svg>
);

NewMail.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

export default NewMail;
