import React from 'react';

// Context
import AppContext from './context/AppContext';

// componens
import FlashNotifications from './components/FlashNotifications';

// Styles
import ResetStyle from './styles/reset';
import GlobalStyle from './styles/global';

import Router from './Router';

const App = () => (
  <>
    <ResetStyle />
    <GlobalStyle />
    <AppContext>
      <Router />
      <FlashNotifications />
    </AppContext>
  </>
);

export default App;
