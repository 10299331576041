import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

// Constants
import paths from '../../constants/paths';

// Providers
import { I18nContext } from '../../providers/I18nContextProvider';

// Redux
import * as questionnaireActions from '../../redux/actions/questionnaire';

// Components
import Seo from '../../components/Seo';
import Page from '../../components/layouts/Page';
import Container from '../../components/layouts/Container';
import Loader from '../../components/elements/Loader';
import Icon from '../../components/icons/Icon';
import Button from '../../components/elements/Button';

// Styling
import mediaQuery from '../../styles/breakpoints';
import colors from '../../styles/colors';
import sizes from '../../styles/sizes';

const Styled = {
  PageInner: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Icon: styled(Icon)`
    margin-bottom: 16px;

    ${mediaQuery.from.S`
      margin-bottom: 32px;
      transform: scale(1.5);
    `}

    ${mediaQuery.from.M`
      transform: scale(2);
    `}
  `,
  Title: styled.h1`
    color: ${colors.blue};
    text-align: center;
    margin-bottom: ${sizes.space * 5}px;
  `,
  Text: styled.p`
    text-align: center;
    margin-bottom: ${sizes.space * 5}px;
  `,
};

const Confirm = ({ confirmEmail }) => {
  const { translate } = useContext(I18nContext);
  const router = useHistory();
  const { id, token } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        await confirmEmail(id, token);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return (
      <Page noFooter>
        <Seo title="Confirm" />
        <Styled.PageInner>
          <Loader />
        </Styled.PageInner>
      </Page>
    );
  }

  return (
    <Page noFooter>
      <Seo title="Confirm" />
      <Container size="medium">
        <Styled.PageInner>
          <Styled.Icon icon="celebrate" width={40} />
          <Styled.Title>{translate('app.questionnaire.confirm.title')}</Styled.Title>
          <Button
            label={translate('app.questionnaire.success.button')}
            onClick={() => router.push(paths.questionnaire)}
          />
        </Styled.PageInner>
      </Container>
    </Page>
  );
};

Confirm.propTypes = {
  confirmEmail: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  (dispatch) => ({
    confirmEmail: (id, token) => dispatch(questionnaireActions.confirmEmail(id, token)),
  })
)(Confirm);
