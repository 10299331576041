import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

// Styles
import mediaQuery from '../../styles/breakpoints';
import sizes from '../../styles/sizes';

const SharedStyling = {
  container: css`
    margin: 0 auto;
    padding: 0 ${({ columnHolder }) => (columnHolder ? 0 : sizes.space * 2)}px;
    width: 100%;

    ${mediaQuery.from.S`
      padding: 0 ${({ columnHolder }) => (columnHolder ? 0 : sizes.space * 4)}px;
    `}

    ${mediaQuery.from.M`
      padding: 0 ${({ columnHolder }) => (columnHolder ? sizes.space * 2 : sizes.space * 6)}px;
    `}
  `,
};

const Styled = {
  ExtraSmall: styled.div`
    ${SharedStyling.container};
    max-width: 600px;
  `,

  Small: styled.div`
    ${SharedStyling.container};
    max-width: 800px;
  `,

  Medium: styled.div`
    ${SharedStyling.container};
    max-width: 1240px;
  `,

  Large: styled.div`
    ${SharedStyling.container};
    max-width: 1600px;
  `,
};

const Container = ({ children, size, columnHolder, className }) => {
  if (size === 'extraSmall') {
    return (
      <Styled.ExtraSmall columnHolder={columnHolder} className={className}>
        {children}
      </Styled.ExtraSmall>
    );
  }
  if (size === 'small') {
    return (
      <Styled.Small columnHolder={columnHolder} className={className}>
        {children}
      </Styled.Small>
    );
  }
  if (size === 'medium') {
    return (
      <Styled.Medium columnHolder={columnHolder} className={className}>
        {children}
      </Styled.Medium>
    );
  }
  return (
    <Styled.Large columnHolder={columnHolder} className={className}>
      {children}
    </Styled.Large>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['extraSmall', 'small', 'medium', 'large']),
  columnHolder: PropTypes.bool,
  className: PropTypes.string,
};

Container.defaultProps = {
  size: 'large',
  columnHolder: false,
  className: '',
};

export default Container;
