// actions
import { CONTENT_FETCH, STATS_FETCH_SUCCESS } from '../actions/content';

const INITIAL_STATE = {};

const newData = {};
export default (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case CONTENT_FETCH:
      // reformat home data
      if (actions.homepage) {
        newData.home = {
          meta: {
            title: actions.homepage[0].data.meta_title,
            description: actions.homepage[0].data.meta_description,
          },
          intro: {
            title: actions.homepage[0].data.intro_title[0].text,
            uspList: actions.homepage[0].data.intro_usp_list.map((usp) => usp.usp),
            image: {
              url: actions.homepage[0].data.intro_image.url,
              alt: actions.homepage[0].data.intro_image.alt,
              dimensions: actions.homepage[0].data.intro_image.dimensions,
            },
          },
          steps: {
            title: actions.homepage[0].data.steps_title[0].text,
            buttonLabel: actions.homepage[0].data.steps_button_label,
            steps: actions.homepage[0].data.steps.map((step) => {
              return {
                title: step.step_title[0].text,
                text: step.step_text[0].text,
              };
            }),
          },
          usps: {
            usps: actions.homepage[0].data.usp_blocks.map((usp) => {
              return {
                title: usp.usp_title[0].text,
                text: usp.usp_text[0].text,
                image: {
                  url: usp.usp_image.url,
                  alt: usp.usp_image.alt,
                  dimensions: usp.usp_image.dimensions,
                },
              };
            }),
            buttonLabel: actions.homepage[0].data.usp_blocks_button_label,
          },
          stats: {
            title: actions.homepage[0].data.stats_title[0].text,
            image: {
              url: actions.homepage[0].data.stats_image.url,
              alt: actions.homepage[0].data.stats_image.alt,
              dimensions: actions.homepage[0].data.stats_image.dimensions,
            },
            subtitle: actions.homepage[0].data.stats_subtitle[0].text,
          },
        };
      }

      // reformat faq data
      if (actions.faq) {
        newData.faq = {
          meta: {
            title: actions.faq[0].data.meta_title,
            description: actions.faq[0].data.meta_description,
          },
          title: actions.faq[0].data.title[0].text,
          accordion: actions.faq[0].data.accordion.map((item) => {
            return {
              question: item.question[0].text,
              answer: item.answer[0].text,
            };
          }),
        };
      }

      if (actions.privacy) {
        newData.privacy = {
          meta: {
            title: actions.privacy[0].data.meta_title,
            description: actions.privacy[0].data.meta_description,
          },
          title: actions.privacy[0].data.title[0].text,
          copy: actions.privacy[0].data.copy,
        };
      }

      if (actions.disclaimer) {
        newData.disclaimer = {
          meta: {
            title: actions.disclaimer[0].data.meta_title,
            description: actions.disclaimer[0].data.meta_description,
          },
          title: actions.disclaimer[0].data.title[0].text,
          copy: actions.disclaimer[0].data.copy,
        };
      }

      return {
        ...state,
        ...newData,
      };

    case STATS_FETCH_SUCCESS: {
      return {
        ...state,
        stats: actions.payload.data,
      };
    }

    default:
      return state;
  }
};
