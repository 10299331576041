import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

// Constants
import paths from '../constants/paths';

// Providers
import { I18nContext } from '../providers/I18nContextProvider';

// Redux
import * as questionnaireActions from '../redux/actions/questionnaire';
import * as flashNotificationsActions from '../redux/actions/flash-notifications';

// Components
import Seo from '../components/Seo';
import Page from '../components/layouts/Page';
import Container from '../components/layouts/Container';
import Icon from '../components/icons/Icon';
import Button from '../components/elements/Button';

// Styling
import mediaQuery from '../styles/breakpoints';
import colors from '../styles/colors';
import sizes from '../styles/sizes';

const Styled = {
  PageInner: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Icon: styled(Icon)`
    margin-bottom: 16px;

    ${mediaQuery.from.S`
      margin-bottom: 32px;
      transform: scale(1.5);
    `}

    ${mediaQuery.from.M`
      transform: scale(2);
    `}
  `,
  Title: styled.h1`
    color: ${colors.blue};
    text-align: center;
    margin-bottom: ${sizes.space * 5}px;
  `,
  Text: styled.p`
    text-align: center;
    margin-bottom: ${sizes.space * 5}px;
  `,
};

const Unsubscribe = ({ unsubscribe, addFlashNotifications }) => {
  const { translate } = useContext(I18nContext);
  const router = useHistory();
  const { id, token } = useParams();
  const [loading, setLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const handleUnsubscribe = async () => {
    try {
      setLoading(true);
      await unsubscribe(id, token);
      setIsDone(true);
    } catch (error) {
      console.error(error);
      addFlashNotifications([
        {
          title: translate('app.unsubscribe.error.title'),
          message: translate('app.unsubscribe.error.message'),
          type: 'error',
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page noFooter>
      <Seo title="Unsubscribe" />
      <Container size="medium">
        <Styled.PageInner>
          <Styled.Icon icon="door" width={40} />
          {isDone ? (
            <>
              <Styled.Title>{translate('app.unsubscribed.title')}</Styled.Title>
              <Button
                label={translate('app.unsubscribed.button')}
                onClick={() => router.push(paths.questionnaire)}
                loading={loading}
                disabled={loading}
              />
            </>
          ) : (
            <>
              <Styled.Title>{translate('app.unsubscribe.title')}</Styled.Title>
              <Button
                label={translate('app.unsubscribe.button')}
                onClick={handleUnsubscribe}
                loading={loading}
                disabled={loading}
              />
            </>
          )}
        </Styled.PageInner>
      </Container>
    </Page>
  );
};

Unsubscribe.propTypes = {
  unsubscribe: PropTypes.func.isRequired,
  addFlashNotifications: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  (dispatch) => ({
    unsubscribe: (id, token) => dispatch(questionnaireActions.unsubscribe(id, token)),
    addFlashNotifications: (notificationId) =>
      dispatch(flashNotificationsActions.addFlashNotifications(notificationId)),
  })
)(Unsubscribe);
