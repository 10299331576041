// actions
import { FLASH_NOTIFICATIONS } from '../actions/flash-notifications';

const INITIAL_STATE = [];

export default function reducer(state = INITIAL_STATE, actions) {
  switch (actions.type) {
    case FLASH_NOTIFICATIONS: {
      return [...state, ...actions.notifications];
    }
    default:
      return state;
  }
}
