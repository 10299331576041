export const CITIES_FETCH = 'CITIES_FETCH';
export const CITIES_FETCH_SUCCESS = 'CITIES_FETCH_SUCCESS';

export function fetchCities(query) {
  return {
    type: CITIES_FETCH,
    payload: {
      request: {
        url: `/cities?q=${query}&limit=5`,
        method: 'GET',
        data: {
          q: query,
        },
      },
    },
  };
}
