import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';

// Styles
import sizes from '../styles/sizes';

// Components
import MapMarker from './assets/MapMarker';

const Styled = {
  Component: styled.div`
    overflow: hidden;
    height: 400px;
    width: 100%;
    border-radius: ${sizes.borderRadiusImage}px;
  `,
};

const GoogleMaps = ({ location, className }) => (
  <Styled.Component className={className}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API }}
      defaultCenter={location}
      defaultZoom={15}
      options={{
        minZoom: 7,
        maxZoom: 17,
      }}
    >
      <div lat={location.lat} lng={location.lng}>
        <MapMarker />
      </div>
    </GoogleMapReact>
  </Styled.Component>
);

GoogleMaps.propTypes = {
  location: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  className: PropTypes.string,
};

GoogleMaps.defaultProps = {
  className: '',
};

export default GoogleMaps;
