import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

// Styles
import sizes from '../../styles/sizes';
import colors from '../../styles/colors';

const Size = 40;
const BounceOffset = 9;

const rotate = keyframes`
  17% {
    border-bottom-right-radius: 5px;
  }
  25% {
    transform: translateY(${BounceOffset}px) rotate(22.5deg);
  }
  50% {
    transform: translateY(18px) scale(1, 0.9) rotate(45deg) ;
    border-bottom-right-radius: 35px;
  }
  75% {
    transform: translateY(${BounceOffset}px) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
`;

const shadow = keyframes`
  50% {
    transform: scale(1.2, 1);
  }
`;

const Styled = {
  LoaderHolder: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Loader: styled.div`
    position: relative;
    margin: ${sizes.space * 4}px auto;
  `,
  Box: styled.div`
    width: ${Size}px;
    height: ${Size}px;
    background: ${(prop) => (prop.color === 'white' ? colors.white : colors.primary)};
    animation: ${rotate} 0.5s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
  `,
  Shadow: styled.div`
    width: ${Size}px;
    height: ${Size / 10}px;
    background: #000;
    opacity: 0.1;
    position: absolute;
    top: ${Size + BounceOffset}px;
    left: 0;
    border-radius: 50%;
    animation: ${shadow} 0.5s linear infinite;
  `,
};

const Loader = ({ color }) => {
  return (
    <Styled.LoaderHolder>
      <Styled.Loader>
        <Styled.Box color={color} />
        <Styled.Shadow />
      </Styled.Loader>
    </Styled.LoaderHolder>
  );
};

Loader.propTypes = {
  color: PropTypes.oneOf(['primary', 'white']),
};

Loader.defaultProps = {
  color: 'primary',
};

export default Loader;
