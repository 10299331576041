import React from 'react';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';

const List = ({ width, className }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 24 24"
    className={className}
  >
    <path d="M8 3h13v2H8V3zM8 11h13v2H8v-2zM8 19h13v2H8v-2z" fill={colors.black} />
    <path d="M9 4a3 3 0 11-6 0 3 3 0 016 0z" fill={colors.primary} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 5a1 1 0 100-2 1 1 0 000 2zm0 2a3 3 0 100-6 3 3 0 000 6z"
      fill={colors.black}
    />
    <path d="M9 20a3 3 0 11-6 0 3 3 0 016 0z" fill={colors.primary} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 21a1 1 0 100-2 1 1 0 000 2zm0 2a3 3 0 100-6 3 3 0 000 6z"
      fill={colors.black}
    />
    <path d="M9 12a3 3 0 11-6 0 3 3 0 016 0z" fill={colors.primary} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 13a1 1 0 100-2 1 1 0 000 2zm0 2a3 3 0 100-6 3 3 0 000 6z"
      fill={colors.black}
    />
  </svg>
);

List.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

export default List;
