import React, { useContext } from 'react';
import styled from 'styled-components';

// Providers
import { I18nContext } from '../providers/I18nContextProvider';

// Components
import Seo from '../components/Seo';
import Page from '../components/layouts/Page';
import Container from '../components/layouts/Container';

// Styling
import sizes from '../styles/sizes';
import textStyles from '../styles/textStyles';

const Styled = {
  Title: styled.h1`
    margin-bottom: ${sizes.space * 2}px;
  `,
  Email: styled.a`
    ${textStyles.titleLoud};
    text-decoration: none;
  `,
};

const Contact = () => {
  const { translate } = useContext(I18nContext);
  return (
    <Page>
      <Seo title={translate('app.contact.title')} />
      <Container size="small">
        <Styled.Title>{translate('app.contact.title')}</Styled.Title>
        <p>{translate('app.contact.text')}</p>
        <p>
          <Styled.Email href="mailto:support@rplace.nl">support@rplace.nl</Styled.Email>
        </p>
      </Container>
    </Page>
  );
};

export default Contact;
