import React from 'react';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';

const Location = ({ width, className }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 40 40"
    className={className}
  >
    <path
      d="M36.665 16.667c0 9.204-7.462 16.666-16.666 16.666-9.205 0-16.667-7.462-16.667-16.666C3.332 7.462 10.794 0 19.999 0c9.204 0 16.666 7.462 16.666 16.667z"
      fill={colors.white}
    />
    <path
      d="M19.999 0C10.795 0 3.332 7.325 3.332 16.358c0 9.175 7.292 15.447 16.667 23.642 9.375-8.195 16.666-14.467 16.666-23.642C36.665 7.325 29.202 0 20 0zm0 30c-7.365 0-13.334-5.97-13.334-13.333C6.665 9.303 12.634 3.333 20 3.333c7.365 0 13.333 5.97 13.333 13.334C33.332 24.03 27.364 30 19.999 30z"
      fill={colors.black}
    />
    <path
      d="M28.335 16.667a8.333 8.333 0 11-16.667 0 8.333 8.333 0 0116.667 0z"
      fill={colors.primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.001 21.667a5 5 0 100-10 5 5 0 000 10zm0 3.334a8.333 8.333 0 100-16.667 8.333 8.333 0 000 16.667z"
      fill={colors.black}
    />
  </svg>
);

Location.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

export default Location;
