import React from 'react';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';

const Bed = ({ width, className }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 24 24"
    className={className}
  >
    <path d="M4 2h16v9H4V2z" fill={colors.white} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 4H6v5h12V4zM4 2v9h16V2H4z"
      fill={colors.black}
    />
    <path d="M0 18h2v4H0v-4zM22 18h2v4h-2v-4z" fill={colors.black} />
    <path d="M12 10a5 5 0 11-10 0 5 5 0 0110 0z" fill={colors.primary} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 13a3 3 0 100-6 3 3 0 000 6zm0 2A5 5 0 107 5a5 5 0 000 10z"
      fill={colors.black}
    />
    <path d="M22 10a5 5 0 11-10 0 5 5 0 0110 0z" fill={colors.primary} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 13a3 3 0 100-6 3 3 0 000 6zm0 2a5 5 0 100-10 5 5 0 000 10z"
      fill={colors.black}
    />
    <path d="M0 9h24v9H0V9z" fill={colors.white} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 11H2v5h20v-5zM0 9v9h24V9H0z"
      fill={colors.black}
    />
  </svg>
);

Bed.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

export default Bed;
