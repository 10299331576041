import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

// Styles
import mediaQuery from '../../styles/breakpoints';
import sizes from '../../styles/sizes';

// Components
import Header from '../Header';
import Footer from '../Footer';

const Styled = {
  Page: styled.main`
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
  `,
  Content: styled.div`
    ${({ hasHeader }) => hasHeader && `padding-top: 30%;`};
    ${mediaQuery.from.S`
      ${({ hasHeader }) => hasHeader && `padding-top: 20%;`};
    `}
    ${mediaQuery.from.L`
      ${({ hasHeader }) => hasHeader && `padding-top: 15%;`};
    `}
    ${mediaQuery.from.XL`
      ${({ hasHeader }) => hasHeader && `padding-top: 180px;`};
    `}

    ${({ hasFooter }) => hasFooter && `padding-bottom: 20%;`};
    ${mediaQuery.from.M`
      ${({ hasFooter }) => hasFooter && `padding-bottom: 15%;`};
    `}
    ${mediaQuery.from.L`
      ${({ hasFooter }) => hasFooter && `padding-bottom: ${sizes.space * 15}px;`};
    `}
    ${mediaQuery.from.XL`
      ${({ hasFooter }) => hasFooter && `padding-bottom: ${sizes.space * 20}px;`};
    `}
  `,
};

const Page = ({ children, noHeader, noFooter }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Styled.Page>
      {!noHeader && <Header />}
      <Styled.Content hasHeader={!noHeader} hasFooter={!noFooter}>
        {children}
      </Styled.Content>
      {!noFooter && <Footer />}
    </Styled.Page>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  noHeader: PropTypes.bool,
  noFooter: PropTypes.bool,
};

Page.defaultProps = {
  noHeader: false,
  noFooter: false,
};

export default Page;
