import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Components
import Icon from './icons/Icon';

// Styles
import colors from '../styles/colors';
import sizes from '../styles/sizes';

const resize = keyframes`
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
`;

const Styled = {
  Component: styled.div`
    position: fixed;
    z-index: 999999;
    top: 100px;
    right: ${sizes.space * 3}px;
    width: 0;
  `,
  Notification: styled.div`
    display: flex;
    align-items: center;
    position: relative;
    width: 300px;
    border-radius: ${sizes.borderRadius}px;
    overflow: hidden;
    background: #fff;
    ${({ type }) => {
      if (type === 'success') {
        return `border-left: 5px solid ${colors.valid};`;
      }
      if (type === 'warning') {
        return `border-left: 5px solid ${colors.warning};`;
      }
      if (type === 'error') {
        return `border-left: 5px solid ${colors.error};`;
      }
      return `border-left: 5px solid ${colors.grey};`;
    }}

    box-shadow: 0px 2px 15px 1px rgba(0, 0, 0, 0.2);
    height: ${({ shown }) => (shown ? '70px' : '0')};
    margin-top: ${({ shown }) => (shown ? `${sizes.space * 2}px` : '0')};
    transform: translateX(${({ shown }) => (shown ? '-300px' : '50px')});
    transition: transform 0.5s ease, height 0.3s ease-out 0.5s, margin-top 0.3s ease-out 0.5s;
  `,
  Info: styled.div`
    flex: 1;
    overflow: hidden;
    padding: ${sizes.space * 2}px;
  `,
  Title: styled.h5`
    font-weight: bold;
    line-height: 1.6;
    margin: 0;
    color: ${({ type }) => {
      if (type === 'success') {
        return colors.valid;
      }
      if (type === 'warning') {
        return colors.warning;
      }
      if (type === 'error') {
        return colors.error;
      }
      return colors.black;
    }};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  SubTitle: styled.p`
    font-size: 12px;
    line-height: 1.5;
    margin: 0;
    color: ${colors.black};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `,
  CloseBtn: styled.button`
    cursor: pointer;
    margin-left: auto;
    padding: ${sizes.space * 2}px;
    border: none;
    background: transparent;
    opacity: 0.3;
    transition: opacity 0.2s ease-out;

    &:hover {
      opacity: 1;
    }
  `,
  Duration: styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.05;
    background: ${colors.black};
    animation: ${resize} ${({ duration }) => duration}ms linear;
  `,
};

const FlashNotification = ({ notification }) => {
  const history = useHistory();
  let hidden = false;
  if (notification.id) {
    hidden = localStorage.getItem(notification.id);
  }
  const [shown, setShown] = useState(true);

  useEffect(() => {
    let duration = 5000;
    if (notification.duration) {
      duration = notification.duration;
    }
    // if duration is set to 0 do not tween it out automatically
    if (duration !== 0) {
      // auto hide after duration that is set
      setTimeout(() => {
        setShown(false);
      }, duration);
    }
  }, [notification.duration]);

  const handleClose = () => {
    // tween out
    setShown(false);

    // set storage so it stays hidden
    if (notification.stayHiddenOnClose) {
      if (notification.id) {
        localStorage.setItem(notification.id, true);
      } else {
        console.error('To "stayHiddenOnClose" you need to give the message an "id"');
      }
    }
  };

  const handleClick = () => {
    if (notification.link) {
      history.push(notification.link);
    }
  };

  // don't show if storage is set
  if (hidden) return null;

  return (
    <Styled.Notification shown={shown} type={notification.type}>
      <Styled.Info onClick={handleClick}>
        <Styled.Title type={notification.type}>{notification.title}</Styled.Title>
        <Styled.SubTitle>{notification.message}</Styled.SubTitle>
      </Styled.Info>
      <Styled.CloseBtn onClick={handleClose}>
        <Icon icon="cross" width={16} />
      </Styled.CloseBtn>
      <Styled.Duration duration={notification.duration} />
    </Styled.Notification>
  );
};

FlashNotification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string,
    link: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
    type: PropTypes.oneOf(['normal', 'success', 'warning', 'error']),
    duration: PropTypes.number,
    stayHiddenOnClose: PropTypes.bool,
  }).isRequired,
};

const FlashNotifications = ({ notifications }) => (
  <Styled.Component>
    {notifications.map((notification) => (
      <FlashNotification key={notification.title} notification={notification} />
    ))}
  </Styled.Component>
);

FlashNotifications.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
};

FlashNotifications.defaultProps = {
  notifications: [],
};

export default connect((state) => ({
  notifications: state.flashNotifications,
}))(FlashNotifications);
