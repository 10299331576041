import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import Icon from '../icons/Icon';

// Styles
import sizes from '../../styles/sizes';
import textStyles from '../../styles/textStyles';
import colors from '../../styles/colors';

const Styled = {
  Component: styled.a`
    cursor: pointer;
    width: 100%;
    padding: ${sizes.space * 2}px ${sizes.space}px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: padding-right 0.2s ease-out;

    &:hover {
      padding-right: 0;
    }
  `,

  LeftSide: styled.span`
    display: flex;
    align-items: center;
  `,

  Label: styled.span`
    ${textStyles.titleSubtle}
    padding-left: ${sizes.space * 2}px;
    color: ${({ labelColor }) => (labelColor === 'primary' ? colors.primary : colors.black)};
  `,
};

const NavLink = ({ leftIcon, label, labelColor, rightIcon, onClick }) => (
  <Styled.Component onClick={onClick && onClick}>
    <Styled.LeftSide>
      {leftIcon && <Icon icon={leftIcon} width={27} />}
      <Styled.Label labelColor={labelColor}>{label}</Styled.Label>
    </Styled.LeftSide>
    {rightIcon && <Icon icon={rightIcon} width={28} />}
  </Styled.Component>
);

NavLink.propTypes = {
  leftIcon: PropTypes.string,
  label: PropTypes.string,
  labelColor: PropTypes.oneOf(['default', 'primary']),
  rightIcon: PropTypes.string,
  onClick: PropTypes.func,
};

NavLink.defaultProps = {
  leftIcon: '',
  label: '',
  labelColor: 'default',
  rightIcon: '',
  onClick: null,
};

export default NavLink;
