import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Constants
import paths from '../../constants/paths';
import { buyFiltersOverview, rentFiltersOverview } from '../../constants/questionnaire';

// Providers
import { I18nContext } from '../../providers/I18nContextProvider';

// Utils
import { formatMoney } from '../../utils/formatNumber';

// Components
import Seo from '../../components/Seo';
import Page from '../../components/layouts/Page';
import Container from '../../components/layouts/Container';
import Icon from '../../components/icons/Icon';
import NavLink from '../../components/elements/NavLink';
import Stepper from '../../components/elements/FormStepper';

// Styling
import mediaQuery from '../../styles/breakpoints';
import colors from '../../styles/colors';
import sizes from '../../styles/sizes';

const StyledIntro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  margin-bottom: 16px;

  ${mediaQuery.from.S`
      margin-bottom: 32px;
      transform: scale(1.5);
    `}

  ${mediaQuery.from.M`
      transform: scale(2);
    `}
`;

const StyledTitle = styled.h1`
  color: ${colors.blue};
  text-align: center;
  margin-bottom: ${sizes.space * 2}px;
`;

const StyledText = styled.p`
  text-align: center;
  margin-bottom: ${sizes.space * 5}px;
`;

const StyledWrapper = styled.div`
  margin-bottom: ${sizes.space * 8}px;
`;

const Overview = ({ questionnaire }) => {
  const { translate } = useContext(I18nContext);
  const router = useHistory();

  const filters = useMemo(() => {
    let filtersOverview = buyFiltersOverview;
    if (questionnaire.listingType === 'rental') {
      filtersOverview = rentFiltersOverview;
    }

    // add values of filters already set in questionnaire flow
    if (questionnaire.filters) {
      return filtersOverview.map((filter) => {
        let setValue = null;
        let minPrice = false;
        Object.keys(questionnaire.filters).forEach((key) => {
          const value = questionnaire.filters[key];

          if (filter.name === 'price') {
            if (key === 'minPrice' && value) {
              minPrice = value;
            }
            if (key === 'maxPrice') {
              setValue = `max € ${formatMoney(value)}`;

              // if also has minPrice
              if (minPrice) {
                setValue = `€ ${formatMoney(minPrice)} - € ${formatMoney(value)}`;
              }
            }
          }

          if (filter.name === 'location' && key === 'city') {
            setValue = `in ${value.name}`;
          }

          if (filter.name === 'size' && key === 'minSize') {
            setValue = `${value} m2`;
          }

          if (filter.name === 'rooms' && key === 'rooms') {
            setValue = `Min ${value} rooms`;
          }

          if (filter.name === 'bedrooms' && key === 'bedrooms') {
            setValue = `Min ${value} bedrooms`;
          }

          if (filter.name === 'range' && key === 'range') {
            setValue = `+ ${value} km`;
          }

          if (filter.name === 'constructionYear' && key === 'constructionYear') {
            setValue = `${value}+`;
          }
        });

        return {
          ...filter,
          value: setValue,
        };
      });
    }

    return filtersOverview;
  }, [questionnaire]);

  return (
    <Page noFooter>
      <Seo title="Overview" />
      <section>
        <StyledWrapper>
          <Container size="medium">
            <StyledIntro>
              <StyledIcon icon="filter" width={40} />
              <StyledTitle>{translate('app.questionnaire.overview.title')}</StyledTitle>
              <StyledText>{translate('app.questionnaire.overview.text')}</StyledText>
            </StyledIntro>
          </Container>
          <Container size="extraSmall">
            {filters.map((filter) => {
              return (
                <NavLink
                  key={filter.name}
                  leftIcon={filter.icon}
                  label={filter.value || translate(filter.label)}
                  labelColor={filter.value ? 'primary' : 'default'}
                  rightIcon={filter.value ? 'validated' : 'arrow'}
                  onClick={() => router.push(paths.questionnaireEdit(filter.name))}
                />
              );
            })}
          </Container>
        </StyledWrapper>
        <Stepper
          nextLabel={translate('app.form.finish')}
          onBackClick={() => router.push(paths.questionnaire)}
          onNextClick={() => router.push(paths.questionnaireRegister)}
        />
      </section>
    </Page>
  );
};

Overview.propTypes = {
  questionnaire: PropTypes.shape({
    listingType: PropTypes.oneOf(['purchase', 'rental']).isRequired,
    activeStepIndex: PropTypes.number.isRequired,
    filters: PropTypes.shape({}),
  }).isRequired,
};

export default connect((state) => ({
  questionnaire: state.questionnaire,
}))(Overview);
