import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Redux
import * as contentActions from '../redux/actions/content';

// Providers
import { I18nContext } from '../providers/I18nContextProvider';

// Components
import Seo from '../components/Seo';
import Page from '../components/layouts/Page';
import Container from '../components/layouts/Container';
import Richtext from '../components/elements/Richtext';

// Styling
import sizes from '../styles/sizes';

// Components
import Loader from '../components/elements/Loader';

const StyledTitle = styled.h1`
  margin-bottom: ${sizes.space * 4}px;
`;

const Terms = ({ fetchContent, content }) => {
  const { langCode } = useContext(I18nContext);

  useEffect(() => {
    fetchContent(langCode, 'disclaimer');
  }, []);

  if (!content.disclaimer) {
    return <Loader />;
  }

  return (
    <Page>
      <Seo
        title={content.disclaimer.meta.title}
        description={content.disclaimer.meta.description}
      />
      <Container size="small">
        <StyledTitle>{content.disclaimer.title}</StyledTitle>
        <Richtext copy={content.disclaimer.copy} />
      </Container>
    </Page>
  );
};

Terms.propTypes = {
  fetchContent: PropTypes.func.isRequired,
  content: PropTypes.shape({
    disclaimer: PropTypes.shape({
      meta: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
      title: PropTypes.string,
      copy: PropTypes.string,
    }),
  }).isRequired,
};

export default connect(
  (state) => ({
    content: state.content,
  }),
  (dispatch) => ({
    fetchContent: (locale, page) => dispatch(contentActions.fetchContent(locale, page)),
  })
)(Terms);
