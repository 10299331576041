import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory, Link } from 'react-router-dom';

// Constants
import paths from '../constants/paths';
import { fieldTypes } from '../constants/questionnaire';

// Providers
import { I18nContext } from '../providers/I18nContextProvider';

// Redux
import * as citiesActions from '../redux/actions/cities';
import * as questionnaireActions from '../redux/actions/questionnaire';

// Components
import Container from './layouts/Container';
import Icon from './icons/Icon';
import FilterField from './FilterField';
import Suggestions from './formFields/Suggestions';
import Stepper from './elements/FormStepper';

// Styling
import colors from '../styles/colors';
import sizes from '../styles/sizes';
import mediaQuery from '../styles/breakpoints';

const StyledStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledIntro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledForm = styled.form`
  width: 100%;
  margin-bottom: ${sizes.space * 8}px;
`;

const StyledIcon = styled(Icon)`
  margin-bottom: 16px;

  ${mediaQuery.from.S`
      margin-bottom: 32px;
      transform: scale(1.5);
    `}

  ${mediaQuery.from.M`
      transform: scale(2);
    `}
`;

const StyledTitle = styled.h1`
  color: ${colors.blue};
  text-align: center;
  margin-bottom: ${sizes.space * 2}px;
`;

const StyledText = styled.p`
  text-align: center;
  margin-bottom: ${sizes.space * 5}px;
`;

const StyledLink = styled(Link)`
  position: relative;
  text-decoration: none;
  color: ${colors.black};

  :after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: ${colors.black};
  }
`;

const StyledFieldHolder = styled.div`
  margin-bottom: ${sizes.space * 2}px;
`;

const QuestionnaireForm = ({
  flow,
  type,
  cities,
  questionnaire,
  fetchCities,
  storeData,
  nextStep,
  prevStep,
}) => {
  const { translate } = useContext(I18nContext);

  const activeStep = flow.steps[questionnaire.activeStepIndex];

  // create validation rules from dynamic flow data
  const schema = {};
  if (activeStep) {
    activeStep.fields.forEach((field) => {
      if (field.required) {
        if (
          field.type === fieldTypes.text ||
          field.type === fieldTypes.autocomplete ||
          field.type === fieldTypes.currency
        ) {
          schema[field.name] = yup.string().required(translate('app.form.errors.required'));
        }
        if (field.type === fieldTypes.Checkbox) {
          schema[field.name] = yup.bool().oneOf([true], translate('app.form.errors.required'));
        }
      }

      // Exception: Think of a nicer way to do this more dynamicly
      if (field.required && field.additionalValidation) {
        schema[field.name] = yup
          .object({
            name: yup.string().required(translate('app.buy.form.place.errors.required')),
            value: yup.string().required(translate('app.buy.form.place.errors.required')),
          })
          .typeError(translate('app.buy.form.place.errors.required'))
          .test('valid', translate('app.buy.form.errors.valid'), (place) => {
            return cities.map((city) => {
              return city.value.includes(place.value);
            });
          });
      }
    });
  }

  const router = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yup.object().shape(schema)),
  });

  useEffect(() => {
    if (!activeStep) {
      // skip to overview when already filled in
      router.push(paths.questionnaireOverview);
    }
  }, [activeStep]);

  /**
   * Set default values
   */
  useEffect(() => {
    if (questionnaire.filters) {
      Object.keys(questionnaire.filters).forEach((key) => {
        const value = questionnaire.filters[key];
        // Exception: Think of a nicer way to do this more dynamicly
        // TODO: add markup for currency default values adding € and dots
        if (value) {
          setValue(key, value);
        }
      });
    }
  }, [questionnaire.filters]);

  const handleSetValue = (name, value) => {
    setValue(name, value);

    // Exception: Think of a nicer way to do this more dynamicly
    if (name === 'city') {
      fetchCities(value);
    }
  };

  const onSubmit = async (data) => {
    await storeData(type, data);
    await nextStep();

    // if is at last step
    if (questionnaire.activeStepIndex + 1 === flow.steps.length) {
      router.push(paths.questionnaireOverview);
    }
  };

  if (!activeStep) return null;

  return (
    <StyledStep>
      <Container size="medium">
        <StyledIntro>
          <StyledIcon icon={activeStep.icon} width={40} />
          <StyledTitle>{translate(activeStep.title)}</StyledTitle>
          {activeStep.text && (
            <StyledText>
              {translate(activeStep.text)}{' '}
              {activeStep.link && (
                <StyledLink
                  to={type === 'rental' ? paths.questionnaireBuy : paths.questionnaireRent}
                >
                  {translate(activeStep.link)}
                </StyledLink>
              )}
            </StyledText>
          )}
        </StyledIntro>
      </Container>

      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Container size="extraSmall">
          {activeStep.fields.map((field) => (
            <StyledFieldHolder key={field.name}>
              <FilterField
                register={register}
                defaultValue={getValues(field.name)}
                value={watch(field.name)}
                field={field}
                errors={errors}
                setValue={handleSetValue}
                autoCompleteValues={field.name === 'city' ? cities : []} // Exception: Think of a nicer way to do this more dynamicly
              />
              {field.suggestions && (
                <Suggestions
                  suggestions={field.suggestions}
                  setValue={(value) => setValue(field.name, value)}
                />
              )}
            </StyledFieldHolder>
          ))}
        </Container>

        <Stepper
          nextLabel={translate('app.form.next')}
          onBackClick={() => {
            // if is at first step
            if (questionnaire.activeStepIndex === 0) {
              router.push(paths.questionnaire);
              return;
            }
            prevStep();
          }}
        />
      </StyledForm>
    </StyledStep>
  );
};

QuestionnaireForm.propTypes = {
  flow: PropTypes.shape({
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        text: PropTypes.string,
        link: PropTypes.string,
        fields: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string,
            placeholder: PropTypes.string,
          })
        ),
      })
    ),
  }).isRequired,
  type: PropTypes.oneOf(['purchase', 'rental']).isRequired,
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  questionnaire: PropTypes.shape({
    activeStepIndex: PropTypes.number.isRequired,
    filters: PropTypes.shape({}),
  }).isRequired,
  fetchCities: PropTypes.func.isRequired,
  storeData: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    cities: state.cities.items,
    questionnaire: state.questionnaire,
  }),
  (dispatch) => ({
    fetchCities: (query) => dispatch(citiesActions.fetchCities(query)),
    storeData: (type, data) => dispatch(questionnaireActions.storeData(type, data)),
    nextStep: () => dispatch(questionnaireActions.nextStep()),
    prevStep: () => dispatch(questionnaireActions.prevStep()),
  })
)(QuestionnaireForm);
