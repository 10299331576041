import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

// Constants
import paths from '../constants/paths';

// Components
import Container from './layouts/Container';
import Button from './elements/Button';
import Shape from './assets/Shape';

// Styles
import sizes from '../styles/sizes';
import mediaQuery from '../styles/breakpoints';

const Styled = {
  ShapeHolder: styled.div`
    position: absolute;
    z-index: 0;
    top: 0;
    width: 100%;
    height: 100%;
    min-height: 1000px;
    overflow: hidden;
  `,

  Shape: styled(Shape)`
    position: absolute;
    z-index: 0;
    top: -2%;
    right: -20%;
    width: 80%;
    max-width: 1300px;

    ${mediaQuery.from.XS`
      top: -80px;
      right: -140px;
      width: 80%;
    `}

    ${mediaQuery.from.S`
      top: -100px;
      right: -20%;
      width: 80%;
    `}

    ${mediaQuery.from.M`
      top: -400px;
      right: -18%;
      width: 80%;
    `}

    ${mediaQuery.from.L`
      top: -450px;
      right: -20%;
      width: 90%;
    `}

    ${mediaQuery.from.XL`
      top: -640px;
      right: -240px;
    `}
  `,

  Row: styled.div`
    display: block;
    align-items: center;
    justify-content: space-between;

    ${mediaQuery.from.L`
      display: flex;
    `}
  `,

  Content: styled.div`
    position: relative;
    z-index: 1;
    padding-bottom: ${sizes.space * 6}px;

    ${mediaQuery.from.L`
      padding-bottom: 0;
      margin-right: ${sizes.space * 4}px;
    `}

    ul {
      margin-bottom: ${sizes.space * 4}px;
      list-style-image: url('/images/list-shape.svg');

      li {
        padding-left: 10px;
      }
    }

    button {
      margin: 0 auto;
      display: block;

      ${mediaQuery.from.L`
        margin: 0;
      `}
    }
  `,

  Title: styled.h1`
    max-width: 800px;
    margin-bottom: ${sizes.space * 4}px;
  `,

  ImageWrapper: styled.div`
    display: flex;
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    border-radius: ${sizes.borderRadiusImage}px;
    overflow: hidden;

    ${mediaQuery.from.L`
      min-width: 40%;
      margin-left: auto;
      margin-right: 0;
    `}
  `,
};

const Intro = ({ title, uspList, image, ctaLabel }) => {
  const router = useHistory();
  return (
    <>
      <Styled.ShapeHolder>
        <Styled.Shape />
      </Styled.ShapeHolder>
      <Container>
        <Styled.Row>
          <Styled.Content>
            <Styled.Title>{title}</Styled.Title>
            <ul>
              {uspList.map((usp) => (
                <li key={usp}>{usp}</li>
              ))}
            </ul>
            <Button label={ctaLabel} onClick={() => router.push(paths.questionnaire)} />
          </Styled.Content>
          <Styled.ImageWrapper>
            <img src={image.url} alt={image.alt} />
          </Styled.ImageWrapper>
        </Styled.Row>
      </Container>
    </>
  );
};

Intro.propTypes = {
  title: PropTypes.string.isRequired,
  uspList: PropTypes.arrayOf(PropTypes.string).isRequired,
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
    dimensions: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }).isRequired,
  ctaLabel: PropTypes.string.isRequired,
};

export default Intro;
