import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Styles
// import sizes from '../../styles/sizes';
import colors from '../../styles/colors';
import textStyles from '../../styles/textStyles';

// Components
import Icon from '../icons/Icon';
import Error from '../elements/Error';

const Styled = {
  Component: styled.div`
    position: relative;
  `,
  Label: styled.label`
    ${textStyles.titleSubtle};
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    transition: color 120ms ease-in-out;
  `,
  Box: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 16px;
    border-radius: 8px;
    border: 2px solid ${({ error }) => (error ? colors.error : colors.black)};
    transition: border 120ms ease-in-out, background-color 120ms ease-in-out;
  `,
  Icon: styled(Icon)`
    opacity: 0;
    transition: opacity 120ms ease-in-out;
  `,
};

const StyledInput = styled.input`
  position: absolute;
  opacity: 0;

  + ${Styled.Label} {
    :hover,
    :focus {
      ${Styled.Box} {
        border-color: ${colors.primary};
      }
    }
  }

  :checked {
    + ${Styled.Label} {
      color: ${colors.primary};

      :hover {
        ${Styled.Box} {
          border-color: ${({ error }) => (error ? colors.error : colors.black)};
        }
      }

      ${Styled.Box} {
        background-color: ${colors.primary};

        > ${Styled.Icon} {
          opacity: 1;
        }
      }
    }
  }

  :focus {
    + ${Styled.Label} > ${Styled.Box} {
      border-color: ${colors.primary};
    }
  }
`;

const Checkbox = ({ register, name, label, error }) => (
  <Styled.Component>
    <StyledInput {...register(name)} type="checkbox" name={name} id={name} error={!!error} />
    <Styled.Label htmlFor={name}>
      <Styled.Box error={!!error}>
        <Styled.Icon icon="check" width={16} />
      </Styled.Box>
      {label}
    </Styled.Label>
    {!!error && <Error label={error} />}
  </Styled.Component>
);

Checkbox.propTypes = {
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
};

Checkbox.defaultProps = {
  error: '',
};

export default Checkbox;
