import { css } from 'styled-components';

// Styling
import fonts from './fonts';
import mediaQuery from './breakpoints';

// Text styles
export default {
  titleExtreme: css`
    margin: 0;
    font-family: ${fonts.title};
    font-size: 32px;
    font-weight: 700;
    line-height: 1.125;

    ${mediaQuery.from.S`
      font-size: 56px;
    `};

    ${mediaQuery.from.L`
      font-size: 64px;
    `};
  `,
  titleLoud: css`
    margin: 0;
    font-family: ${fonts.title};
    font-size: 32px;
    font-weight: 700;
    line-height: 1.125;

    ${mediaQuery.from.S`
      font-size: 40px;
    `};

    ${mediaQuery.from.L`
      font-size: 48px;
    `};
  `,
  titleNormal: css`
    margin: 0;
    font-family: ${fonts.title};
    font-size: 24px;
    font-weight: 700;
    line-height: 1.125;

    ${mediaQuery.from.S`
      font-size: 32px;
    `};
  `,
  titleSubtle: css`
    margin: 0;
    font-family: ${fonts.title};
    font-size: 18px;
    font-weight: 700;
    line-height: 1.125;

    ${mediaQuery.from.L`
      font-size: 20px;
    `};
  `,
  screamingHighlight: css`
    margin: 0;
    font-family: ${fonts.title};
    font-size: 64px;
    font-weight: 700;
    line-height: 1.125;

    ${mediaQuery.from.S`
      font-size: 80px;
    `};

    ${mediaQuery.from.L`
      font-size: 96px;
    `};
  `,
  bodyLarge: css`
    margin: 0;
    font-family: ${fonts.body};
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;

    ${mediaQuery.from.L`
      font-size: 18px;
    `};
  `,
  bodySmall: css`
    margin: 0;
    font-family: ${fonts.body};
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
  `,
};
