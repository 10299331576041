import React, { useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

// Constants
import paths from '../../constants/paths';

// Providers
import { I18nContext } from '../../providers/I18nContextProvider';

// Components
import Seo from '../../components/Seo';
import Page from '../../components/layouts/Page';
import Container from '../../components/layouts/Container';
import Icon from '../../components/icons/Icon';
import Button from '../../components/elements/Button';

// Styling
import colors from '../../styles/colors';
import sizes from '../../styles/sizes';
import mediaQuery from '../../styles/breakpoints';

const Restyled = {
  Icon: styled(Icon)`
    margin-bottom: 16px;

    ${mediaQuery.from.S`
      margin-bottom: 32px;
      transform: scale(1.5);
    `}

    ${mediaQuery.from.M`
      transform: scale(2);
    `}
  `,
};

const Styled = {
  Inner: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Title: styled.h1`
    color: ${colors.blue};
    text-align: center;
    margin-bottom: ${sizes.space * 10}px;
  `,
  Buttons: styled.div`
    display: flex;
    flex-direction: column;
    > button:first-child {
      margin-bottom: ${sizes.space * 4}px;
    }

    ${mediaQuery.from.S`
      flex-direction: row;
      justify-content: center;
      > button:first-child {
        margin-right: ${sizes.space * 4}px;
        margin-bottom: 0;
      }
    `}
  `,
};

const Questionnaire = () => {
  const { translate } = useContext(I18nContext);
  const router = useHistory();
  return (
    <Page>
      <Seo title="Questionnaire" />
      <Container>
        <Styled.Inner>
          <Restyled.Icon icon="houses" width={40} />
          <Styled.Title>{translate('app.questionnaire.title')}</Styled.Title>
          <Styled.Buttons>
            <Button
              label={translate('app.questionnaire.buyButton')}
              onClick={() => router.push(paths.questionnaireBuy)}
            />
            <Button
              label={translate('app.questionnaire.rentButton')}
              onClick={() => router.push(paths.questionnaireRent)}
            />
          </Styled.Buttons>
        </Styled.Inner>
      </Container>
    </Page>
  );
};

export default Questionnaire;
