import React from 'react';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';

const Label = ({ width, className }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 24 16"
    className={className}
  >
    <path
      d="M1 1h16.5l5.25 7-5.25 7H1V1z"
      fill={colors.primary}
      stroke={colors.black}
      strokeWidth={2}
    />
  </svg>
);

Label.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

export default Label;
