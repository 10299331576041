import React from 'react';
import PropTypes from 'prop-types';

const Map = ({ onClick, className }) => (
  <svg viewBox="0 0 80 80" fill="none" className={className} onClick={onClick}>
    <path fill="#E4E4E4" d="M0 0h80v80H0z" />
    <path d="M51 43h29v37H51V43z" fill="#C6E7FF" />
    <path d="M42 51l8 10h5.5V41.5H42V51z" fill="#C6E7FF" />
    <path
      fill="#fff"
      stroke="#fff"
      d="M16.5.5h3v34h-3zM40.5.5h3v42h-3zM60.5.5h3v42h-3zM16.5 48.5h3v31h-3z"
    />
    <circle cx={18} cy={41} r={6} stroke="#fff" strokeWidth={4} />
    <path d="M0 24h17.5L30 14.5h11M51.5 80V62.5L42 51V39" stroke="#fff" strokeWidth={4} />
    <path fill="#fff" d="M23 39h57v4H23zM63 23h17v4H63z" />
    <path d="M64 27h16v12H64V27z" fill="#FAD26B" />
    <path d="M0 0h16v22H0V0z" fill="#D3EE9B" />
    <path
      d="M19.442 38.279c1.382-2.664 6.325-5.953 11.008-9.756 4.797-3.722 9.301-7.958 12.52-10.67 3.383-2.55 5.48-2.68 8.586.947 2.96 3.514 6.374 10.879 8.358 20.522 1.837 9.579 1.333 18.452-1.431 22.222-2.764 4.028-6.846 2.776-13.464-1.396a901.704 901.704 0 01-19.365-12.884c-5.285-3.722-7.724-6.161-6.212-8.985z"
      fill="#53D28D"
    />
    <path d="M37 35.706L43 30l6 5.706V46H37V35.706z" fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.001 36.414l5.071-5.07 4.929 4.928V45h-10v-8.586zm-2 2l-2.364 2.364-1.414-1.414 10.85-10.85 10.849 10.85-1.415 1.414-2.506-2.506V47h-14v-8.586z"
      fill="#000"
    />
  </svg>
);

Map.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string.isRequired,
};

Map.defaultProps = {
  onClick: () => {},
};

export default Map;
