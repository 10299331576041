import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Styles
import colors from '../../styles/colors';

const Styled = {
  Path: styled.path`
    tranition: fill 0.2s ease-in-out;
  `,
};

const Email = ({ width, className, color }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 24 18"
    className={className}
  >
    <Styled.Path
      d="M0 0v18h24V0H0zm21.518 2L12 9.713 2.482 2h19.036zM2 16V4.183l10 8.104 10-8.104V16H2z"
      fill={color}
    />
  </svg>
);

Email.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  color: PropTypes.string,
};

Email.defaultProps = {
  color: colors.black,
};

export default Email;
