import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const Context = createContext(null);

const AppContext = ({ children }) => {
  // ! Delete this dummy state whenever a state has been added
  const [state, setState] = useState(false);

  return (
    <Context.Provider
      value={{
        state,
        setState: (bool) => setState(bool),
      }}
    >
      {children}
    </Context.Provider>
  );
};

AppContext.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Context };
export default AppContext;
