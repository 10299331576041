import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';

const Styled = {
  Component: styled.div`
    display: flex;
    align-items: center;
  `,
  Bar: styled.div`
    width: 100%;
    height: 8px;
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      width: ${({ percentage }) => percentage}%;
      height: 8px;
      position: absolute;
      left: 0;
      top: 0;
      background: ${colors.primary};
      transition: width 0.2s;
    }
  `,
};

const ProgressBar = ({ percentage }) => (
  <Styled.Component>
    <Styled.Bar percentage={percentage} />
  </Styled.Component>
);

ProgressBar.propTypes = {
  percentage: PropTypes.number,
};

ProgressBar.defaultProps = {
  percentage: 0,
};

export default ProgressBar;
