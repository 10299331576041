import React from 'react';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';

const Parcel = ({ width, className }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 24 24"
    className={className}
  >
    <path d="M12 4h8v9h-8V4z" fill={colors.primary} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 4H4v16h16V4zM2 2v20h20V2H2z"
      fill={colors.black}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 12V2H2v20h20V12H12zm8 2H10V4H4v16h16v-6z"
      fill={colors.black}
    />
  </svg>
);

Parcel.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

export default Parcel;
