import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Providers
import { I18nContext } from '../../providers/I18nContextProvider';

// Styles
import colors from '../../styles/colors';
import sizes from '../../styles/sizes';
import textStyles from '../../styles/textStyles';

// Components
import Icon from '../icons/Icon';

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  margin-bottom: ${sizes.space}px;
  background-color: ${colors.primary};
  border-radius: 50%;
  transition: background-color 0.2s ease-in-out;
`;

const Styled = {
  Component: styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: ${sizes.space * 2}px;

    :last-of-type {
      margin-right: 0;
    }

    > a {
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    :hover {
      ${Circle} {
        background-color: ${colors.secondary};
      }
    }
  `,
  Label: styled.span`
    ${textStyles.bodySmall};
    color: ${colors.black};
  `,
};

const ShareButton = ({ type, url, title }) => {
  const { translate } = useContext(I18nContext);
  switch (type) {
    case 'email':
      return (
        <Styled.Component>
          <a href={`mailto:?subject=${title}&body=${url}`}>
            <Circle>
              <Icon icon={type} width={24} />
            </Circle>
            <Styled.Label>{translate('app.share.email')}</Styled.Label>
          </a>
        </Styled.Component>
      );
    case 'whatsapp':
      return (
        <Styled.Component>
          <a href={`whatsapp://send?text=${url}`} data-action="share/whatsapp/share">
            <Circle>
              <Icon icon={type} width={24} />
            </Circle>
            <Styled.Label>{translate('app.share.whatsapp')}</Styled.Label>
          </a>
        </Styled.Component>
      );
    case 'copy':
      return (
        <Styled.Component>
          <CopyToClipboard text={url}>
            <div>
              <Circle>
                <Icon icon={type} width={18} />
              </Circle>
              <Styled.Label>{translate('app.share.copy')}</Styled.Label>
            </div>
          </CopyToClipboard>
        </Styled.Component>
      );
    default:
      return null;
  }
};

ShareButton.propTypes = {
  type: PropTypes.oneOf(['email', 'whatsapp', 'copy']).isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
};

ShareButton.defaultProps = {
  title: '',
};

export default ShareButton;
