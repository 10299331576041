export const STORE_DATA = 'STORE_DATA';
export const NEXT_STEP = 'NEXT_STEP';
export const PREV_STEP = 'PREV_STEP';
export const POST_FILTERS = 'POST_FILTERS';
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const UNSUBSCRIBE = 'UNSUBSCRIBE';

export function storeData(type, data) {
  return {
    type: STORE_DATA,
    listingType: type,
    data,
  };
}

export function nextStep() {
  return {
    type: NEXT_STEP,
  };
}

export function prevStep() {
  return {
    type: PREV_STEP,
  };
}

export function submitData(email, data) {
  const formattedData = {};
  formattedData.email = email;
  formattedData.filters = {
    listing_type: data.listingType || null,
    price: {
      min: 0,
    },
    size: {
      min: 0,
    },
    rooms: {
      min: 0,
    },
    bedrooms: {
      min: 0,
    },
    bathrooms: {
      min: 0,
    },
    construction_year: {
      min: 0,
    },
    location: {
      geohash: '',
      radius: 6,
    },
  };

  if (data.filters) {
    Object.keys(data.filters).forEach((key) => {
      const value = data.filters[key];

      if (value) {
        if (key === 'minPrice') {
          formattedData.filters.price.min = parseInt(value, 10);
        }

        if (key === 'maxPrice') {
          formattedData.filters.price.max = parseInt(value, 10);
        }

        if (key === 'minSize') {
          formattedData.filters.size.min = parseInt(value, 10);
        }

        if (key === 'bedrooms') {
          formattedData.filters.bedrooms.min = parseInt(value, 10);
        }

        if (key === 'rooms') {
          formattedData.filters.rooms.min = parseInt(value, 10);
        }

        if (key === 'constructionYear') {
          formattedData.filters.construction_year.min = parseInt(value, 10);
        }

        if (key === 'range') {
          formattedData.filters.location.radius = parseInt(value, 10);
        }

        if (key === 'city') {
          formattedData.filters.location.geohash = value.value;
        }
      }
    });
  }

  return {
    type: POST_FILTERS,
    payload: {
      request: {
        url: '/alerts',
        method: 'POST',
        data: formattedData,
      },
    },
  };
}

export function confirmEmail(id, token) {
  return {
    type: CONFIRM_EMAIL,
    payload: {
      request: {
        url: `/alerts/${id}/confirm`,
        method: 'PUT',
        data: {
          token,
        },
      },
    },
  };
}

export function unsubscribe(id, token) {
  return {
    type: UNSUBSCRIBE,
    payload: {
      request: {
        url: `/alerts/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
}
