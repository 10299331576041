// actions
import { CITIES_FETCH_SUCCESS } from '../actions/cities';

const INITIAL_STATE = {
  items: [],
};

export default (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case CITIES_FETCH_SUCCESS:
      const { data } = actions.payload;

      const cities = data.map((item) => {
        const city = item;
        city.value = city.geohash;
        delete city.geohash;
        return city;
      });

      return {
        ...state,
        items: cities,
      };

    default:
      return state;
  }
};
