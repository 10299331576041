import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

// Constants
import { fieldTypes } from '../constants/questionnaire';

// Providers
import { I18nContext } from '../providers/I18nContextProvider';

// Components
import TextField from './formFields/TextField';
import AutocompleteField from './formFields/AutocompleteField';
import Checkbox from './formFields/Checkbox';
import Radio from './formFields/Radio';

const FilterField = ({
  register,
  defaultValue,
  value,
  field,
  errors,
  setValue,
  autoCompleteValues,
}) => {
  const { translate } = useContext(I18nContext);

  const label = useMemo(() => {
    if (field.label && !field.required) {
      return `${translate(field.label)} (${translate('app.form.optional')})`;
    }

    if (field.label) {
      return translate(field.label);
    }

    return '';
  }, [field.label, field.required]);

  if (field.type === fieldTypes.autocomplete) {
    return (
      <AutocompleteField
        {...register(field.name)}
        name={field.name}
        value={value}
        label={label}
        placeholder={translate(field.placeholder)}
        setFocus={field.setFocus}
        error={errors[field.name]?.message}
        clearValue={() => setValue(field.name, '')}
        setValue={(v) => setValue(field.name, v)}
        autoCompleteValues={autoCompleteValues}
        onChange={(v) => {
          setValue(field.name, v);
        }}
      />
    );
  }

  if (field.type === fieldTypes.currency) {
    return (
      <NumberFormat
        {...register(field.name)}
        name={field.name}
        defaultVal={defaultValue}
        value={value}
        label={label}
        placeholder={translate(field.placeholder)}
        error={errors[field.name]?.message}
        clearValue={() => setValue(field.name, '')}
        // Custom NumberForm props:
        customInput={TextField}
        thousandSeparator="."
        decimalSeparator=","
        prefix="€ "
        allowNegative={false}
        isNumericString
        onValueChange={(v) => {
          setValue(field.name, v.value);
        }}
      />
    );
  }

  if (field.type === fieldTypes.checkbox) {
    return (
      <Checkbox
        register={register}
        name={field.name}
        label={label}
        error={errors[field.name]?.message}
      />
    );
  }

  if (field.type === fieldTypes.radio) {
    return field.options.map((option) => (
      <Radio
        register={register}
        key={option.value}
        name={field.name}
        value={option.value}
        checked={`${value}` === `${option.value}`}
        error={errors[field.name]?.message}
        onChange={(v) => {
          setValue(field.name, v);
        }}
      >
        {option.label}
      </Radio>
    ));
  }

  if (field.type === fieldTypes.text) {
    return (
      <TextField
        {...register(field.name)}
        name={field.name}
        value={value}
        label={label}
        placeholder={translate(field.placeholder)}
        clearValue={() => setValue(field.name, '')}
        setFocus={field.setFocus}
        error={errors[field.name]?.message}
      />
    );
  }

  return null;
};

FilterField.propTypes = {
  register: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  field: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    setFocus: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  errors: PropTypes.shape({}),
  setValue: PropTypes.func.isRequired,
  autoCompleteValues: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

FilterField.defaultProps = {
  defaultValue: '',
  value: '',
  errors: {},
  autoCompleteValues: [],
};

export default FilterField;
