import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

// Constants
import paths from '../constants/paths';

// Layouts
import Container from './layouts/Container';

// Components
import Button from './elements/Button';

// Styles
import sizes from '../styles/sizes';
import colors from '../styles/colors';
import mediaQuery from '../styles/breakpoints';
import textStyles from '../styles/textStyles';

const Styled = {
  Component: styled.div`
    min-height: 30vh;
  `,
  Inner: styled.div`
    width: 100%;
  `,
  Title: styled.h2`
    text-align: center;
    margin-bottom: ${sizes.space * 2}px;
  `,
  Row: styled.ol`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0;
    list-style: none;
    counter-reset: my-awesome-counter;

    li {
      counter-increment: my-awesome-counter;

      &:before {
        content: counter(my-awesome-counter) '. ';
        ${textStyles.screamingHighlight};
        display: block;
        margin-bottom: ${sizes.space}px;
      }
    }

    ${mediaQuery.from.M`
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    `}
  `,
  Step: styled.li`
    padding: ${sizes.space * 2}px;

    ${mediaQuery.from.S`
      padding: ${sizes.space * 4}px;
    `}

    ${mediaQuery.from.M`
      width: ${100 / 3}%;
      padding: ${sizes.space * 4}px;
    `}
  `,
  StepTitle: styled.h3`
    ${textStyles.titleLoud};
    color: ${colors.primary};
    margin-bottom: ${sizes.space * 2}px;
  `,
  CtaHolder: styled.div`
    display: flex;
    justify-content: center;
    margin-top: ${sizes.space}px;
  `,
};

const Intro = ({ title, steps, buttonLabel }) => {
  const router = useHistory();
  return (
    <Styled.Component>
      <Styled.Inner>
        <Container>
          <Styled.Title>{title}</Styled.Title>
        </Container>
        <Container columnHolder>
          <Styled.Row>
            {steps.map((step) => (
              <Styled.Step key={step.title}>
                <Styled.StepTitle>{step.title}</Styled.StepTitle>
                <p>{step.text}</p>
              </Styled.Step>
            ))}
          </Styled.Row>
        </Container>
        <Container>
          <Styled.CtaHolder>
            <Button label={buttonLabel} onClick={() => router.push(paths.questionnaire)} />
          </Styled.CtaHolder>
        </Container>
      </Styled.Inner>
    </Styled.Component>
  );
};

Intro.propTypes = {
  title: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

export default Intro;
