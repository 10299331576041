import React from 'react';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';

const Money = ({ width, className }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 24 24"
    className={className}
  >
    <path d="M0 4h24v16H0V4z" fill={colors.white} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 6H2v12h20V6zM0 4v16h24V4H0z"
      fill={colors.black}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 5a4 4 0 004 4v2a6 6 0 01-6-6h2zM23 15a4 4 0 00-4 4h-2a6 6 0 016-6v2z"
      fill={colors.black}
    />
    <path d="M17 12a5 5 0 11-10 0 5 5 0 0110 0z" fill={colors.primary} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 15a3 3 0 100-6 3 3 0 000 6zm0 2a5 5 0 100-10 5 5 0 000 10zM5 19a4 4 0 00-4-4v-2a6 6 0 016 6H5zM1 9a4 4 0 004-4h2a6 6 0 01-6 6V9z"
      fill={colors.black}
    />
  </svg>
);

Money.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

export default Money;
