import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import axios from 'axios';

// Constants
import EN from '../constants/en.json';
import NL from '../constants/nl.json';

// To make it easier to read from JSON files
const translations = {
  en: EN,
  nl: NL,
};

const formatLabel = (label, variables) => {
  if (!variables) return label;

  // replace variables if has any
  let formattedLabel = label;
  if (variables) {
    formattedLabel = Object.keys(variables).map((key) => {
      const value = variables[key];
      return formattedLabel.replace(`{${key}}`, value);
    });
  }

  return formattedLabel;
};

// This function will be used to create `translate` function for the context
const getTranslate = (langCode) => (key, variables) => {
  if (!key) return '';
  const keys = key.split('.');

  let getSubLabel = translations[langCode];

  // if has no sub keys
  if (!keys || keys.length < 2) {
    // return label if found otherwise just the key
    return getSubLabel[key] ? formatLabel(getSubLabel[key], variables) : key;
  }

  keys.forEach((subKey) => {
    if (getSubLabel[subKey]) {
      getSubLabel = getSubLabel[subKey];
    }
  });

  // if string is found in json
  if (typeof getSubLabel === 'string' || getSubLabel instanceof String) {
    return formatLabel(getSubLabel, variables);
  }

  // if not found just return key
  return key;
};

const locale = localStorage.getItem('lang') || get(window, 'client.locale', 'nl');
const initialState = {
  langCode: locale,
  translate: getTranslate(locale),
};

// add default language to all api headers
axios.defaults.headers.common['Accept-Language'] = locale;

export const I18nContext = createContext(initialState);

const I18nContextProvider = ({ children }) => {
  /**
   * We're creating a reducer to manage the global state which will sit in
   * I18nContext. For now, the only action we will have is setting language
   *
   * @param {*} state
   * @param {*} action
   * @returns
   */
  const reducer = (state, action) => {
    switch (action.type) {
      case 'setLanguage':
        return {
          langCode: action.payload,
          translate: getTranslate(action.payload),
        };
      default:
        return { ...initialState };
    }
  };

  /* useReducer hook receives a reducer and an initialState to
  return the current state object with a dispatch method to
  dispatch actions. */
  const [state, dispatch] = useReducer(reducer, initialState);

  /* We're Providing state object (langCode and translate method
  in this case) and also the dispatch for the children components */
  return <I18nContext.Provider value={{ ...state, dispatch }}>{children}</I18nContext.Provider>;
};

I18nContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default I18nContextProvider;
