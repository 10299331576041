export default {
  home: '/',
  faq: '/faq',
  contact: '/contact',
  profile: '/profile',
  questionnaire: '/questionnaire',
  questionnaireBuy: '/questionnaire/buy',
  questionnaireRent: '/questionnaire/rent',
  questionnaireOverview: '/questionnaire/overview',
  questionnaireEdit: (name) => `/questionnaire/edit/${name}`,
  questionnaireRegister: '/questionnaire/register',
  questionnaireSuccess: '/questionnaire/success',
  questionnaireConfirm: (id, token) => `/questionnaire/confirm/${id}/${token}`, // !hardcoded in the email
  unsubscribe: (id, token) => `/unsubscribe/${id}/${token}`, // !hardcoded in the email
  listingDetail: (id) => `/listing/${id}`,
  privacy: '/privacy',
  disclaimer: '/terms-and-conditions',
};
