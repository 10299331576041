import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import Container from './layouts/Container';
import Button from './elements/Button';

// Styles
import mediaQuery from '../styles/breakpoints';
import sizes from '../styles/sizes';
import textStyles from '../styles/textStyles';

const Styled = {
  Row: styled.div`
    margin-bottom: ${sizes.space * 8}px;

    ${mediaQuery.from.XS`
      display: flex;
      align-items: center;
      margin-bottom: ${sizes.space * 5}px;
    `}
  `,
  ImageWrapper: styled.div`
    margin-bottom: ${sizes.space * 3}px;

    ${mediaQuery.from.XS`
      min-width: 33.33333%;
      margin-right: ${sizes.space * 4}px;
      margin-bottom: 0;
    `}

    ${mediaQuery.from.M`
      margin-right: ${sizes.space * 6}px;
    `}

    ${mediaQuery.from.L`
      min-width: 30%;
    `}

    ${mediaQuery.from.XL`
      margin-right: ${sizes.space * 8}px;
    `}
  `,
  Title: styled.h2`
    ${textStyles.titleLoud};
    margin-bottom: ${sizes.space * 2}px;
  `,
  Text: styled.p`
    margin-bottom: 0;
  `,
  ButtonWrapper: styled.div`
    display: flex;
    justify-content: center;
  `,
};

const Usps = ({ points, buttonLabel }) => (
  <Container size="medium">
    {points?.map((point) => (
      <Styled.Row key={`row-${point.title}`}>
        <Styled.ImageWrapper>
          <img src={point.image.url} alt={point.image.alt} layout="responsive" />
        </Styled.ImageWrapper>
        <div>
          <Styled.Title>{point.title}</Styled.Title>
          <Styled.Text>{point.text}</Styled.Text>
        </div>
      </Styled.Row>
    ))}
    <Styled.ButtonWrapper>
      <Button label={buttonLabel} />
    </Styled.ButtonWrapper>
  </Container>
);

Usps.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
      dimensions: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
      }),
    })
  ).isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

export default Usps;
