import Arrow from '../components/icons/Arrow';
import Bed from '../components/icons/Bed';
import Bricks from '../components/icons/Bricks';
import Celebrate from '../components/icons/Celebrate';
import Check from '../components/icons/Check';
import Chevron from '../components/icons/Chevron';
import Copy from '../components/icons/Copy';
import Cross from '../components/icons/Cross';
import Door from '../components/icons/Door';
import Distance from '../components/icons/Distance';
import Email from '../components/icons/Email';
import Filter from '../components/icons/Filter';
import HouseApartment from '../components/icons/HouseApartment';
import Houses from '../components/icons/Houses';
import Label from '../components/icons/Label';
import List from '../components/icons/List';
import Location from '../components/icons/Location';
import Money from '../components/icons/Money';
import NewMail from '../components/icons/NewMail';
import Parcel from '../components/icons/Parcel';
import Phone from '../components/icons/Phone';
import Share from '../components/icons/Share';
import Sun from '../components/icons/Sun';
import Surface from '../components/icons/Surface';
import Target from '../components/icons/Target';
import Validated from '../components/icons/Validated';
import WhatsApp from '../components/icons/WhatsApp';

const iconMap = {
  arrow: Arrow,
  bed: Bed,
  bricks: Bricks,
  celebrate: Celebrate,
  check: Check,
  chevron: Chevron,
  copy: Copy,
  cross: Cross,
  door: Door,
  distance: Distance,
  email: Email,
  filter: Filter,
  houseApartment: HouseApartment,
  houses: Houses,
  label: Label,
  list: List,
  location: Location,
  money: Money,
  newMail: NewMail,
  parcel: Parcel,
  phone: Phone,
  share: Share,
  sun: Sun,
  surface: Surface,
  target: Target,
  validated: Validated,
  whatsapp: WhatsApp,
};

export default iconMap;
