import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Providers
import { I18nContext } from '../providers/I18nContextProvider';

// Styles
import colors from '../styles/colors';
import textStyles from '../styles/textStyles';
import sizes from '../styles/sizes';

const Styled = {
  Title: styled.h3`
    ${textStyles.titleLoud};
    margin-bottom: ${sizes.space * 2}px;
  `,
  Content: styled.div`
    position: relative;
    height: ${({ initialHeight }) => `${initialHeight}px`};
    overflow: hidden;
    margin-bottom: ${sizes.space * 2}px;
    transition: height 0.2s ease-in-out;

    :before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: ${sizes.space * 10}px;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      ${({ open }) => open && 'opacity: 0;'};
      transition: opacity 0.2s ease-in-out;
    }
  `,
  ReadMoreButton: styled.button`
    ${textStyles.titleSubtle};
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0;
    border: 0;
    background-color: transparent;
  `,
  Circle: styled.div`
    position: relative;
    width: ${sizes.space * 4}px;
    height: ${sizes.space * 4}px;
    margin-right: ${sizes.space}px;
    background-color: ${colors.primary};
    border-radius: 50%;

    :before,
    :after {
      content: '';
      position: absolute;
      background-color: ${colors.black};
    }

    :before {
      top: 50%;
      left: 8px;
      width: calc(100% - 16px);
      height: 2px;
      transform: translateY(-50%);
    }

    :after {
      ${({ open }) => open && 'display: none'};
      top: 8px;
      left: 50%;
      width: 2px;
      height: calc(100% - 16px);
      transform: translateX(-50%);
    }
  `,
};

const AccordionSingle = ({ title, children, initialHeight, className }) => {
  const { translate } = useContext(I18nContext);
  const ref = useRef();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = open ? `${ref.current.scrollHeight}px` : `${initialHeight}px`;
    }
  }, [open]);

  return (
    <div className={className}>
      {title && <Styled.Title>{title}</Styled.Title>}
      <Styled.Content open={open} ref={ref} initialHeight={initialHeight}>
        {children}
      </Styled.Content>
      <Styled.ReadMoreButton onClick={() => setOpen(!open)}>
        <Styled.Circle open={open} />
        {open
          ? translate('app.accordionSingle.readLess')
          : translate('app.accordionSingle.readMore')}
      </Styled.ReadMoreButton>
    </div>
  );
};

AccordionSingle.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  initialHeight: PropTypes.number,
  className: PropTypes.string.isRequired,
};

AccordionSingle.defaultProps = {
  title: '',
  initialHeight: 112,
};

export default AccordionSingle;
