import React from 'react';
import PropTypes from 'prop-types';

// Constants
import iconMap from '../../constants/iconMap';

// Styles
import colors from '../../styles/colors';

const Icon = ({ icon, width, color, className }) => {
  const IconComponent = iconMap[icon];
  return <IconComponent className={className} width={width} color={color} />;
};

Icon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(iconMap)).isRequired,
  width: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
};

Icon.defaultProps = {
  width: 20,
  className: '',
  color: colors.black,
};

export default Icon;
