import React from 'react';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';

const Bricks = ({ width, className }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 16 11"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.414 3.586L0 5l5.904 5.904L7.32 9.49l-.002-.002 7.714-7.714L13.617.36 5.902 8.074 1.414 3.586z"
      fill={colors.black}
    />
  </svg>
);

Bricks.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

export default Bricks;
