import React from 'react';
import PropTypes from 'prop-types';

const ShapeBottom = ({ className }) => (
  <svg viewBox="0 0 414 176" className={className}>
    <g mask="url(#prefix__a)">
      <path
        d="M-332.631-323.418c26.161-51.716 119.727-115.583 208.368-189.419 90.796-72.278 176.05-154.525 236.992-207.176 64.018-49.536 103.722-52.028 162.508 18.381 56.016 68.228 120.651 211.226 158.2 398.464 34.779 185.992 25.238 358.275-27.085 431.488-52.323 78.197-129.576 53.897-254.843-27.104C28.704 19.903-116.876-79.791-215.059-148.954c-100.029-72.278-146.196-119.633-117.572-174.464z"
        fill="#53D28D"
      />
    </g>
  </svg>
);

ShapeBottom.propTypes = {
  className: PropTypes.string.isRequired,
};

export default ShapeBottom;
