import React from 'react';
import styled from 'styled-components';

// Styling
import colors from '../../styles/colors';
import mediaQuery from '../../styles/breakpoints';

const Styled = {
  Svg: styled.svg`
    with: 52px;
    height: 60px;
    transform: translate(-50%, -50%);

    ${mediaQuery.from.M`
      with: 78px;
      height: 90px;
    `}
  `,
};

const MapMarker = () => (
  <Styled.Svg viewBox="0 0 104 118">
    <path
      d="M1.095 54.769c3.422-6.548 15.662-14.634 27.258-23.982 11.878-9.151 23.03-19.565 31.003-26.23 8.375-6.272 13.568-6.588 21.259 2.326 7.328 8.639 15.783 26.744 20.695 50.45 4.55 23.548 3.302 45.361-3.543 54.63-6.845 9.901-16.951 6.824-33.338-3.431-16.065-10.295-35.11-22.918-47.954-31.674C3.39 67.707-2.65 61.71 1.095 54.768z"
      fill={colors.primary}
    />
    <path d="M48 53.177l10-9.51 10 9.51v17.156H48V53.177z" fill={colors.white} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.666 54.357l8.452-8.452 8.214 8.215v14.547H49.666v-14.31zm-3.334 3.333l-3.94 3.94-2.357-2.356L58.118 41.19 76.2 59.274l-2.357 2.357-4.177-4.178V72H46.332V57.69z"
      fill={colors.black}
    />
  </Styled.Svg>
);

export default MapMarker;
