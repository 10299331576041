import Prismic from '@prismicio/client';

// Utils
import { client } from '../../utils/prismic';

export const CONTENT_FETCH = 'CONTENT_FETCH';
export const STATS_FETCH = 'STATS_FETCH';
export const STATS_FETCH_SUCCESS = 'STATS_FETCH_SUCCESS';

export function fetchContent(locale, page) {
  return (dispatch) => {
    if (!client) {
      throw Error('Prismic credentials not set');
    }

    return client
      .query(Prismic.Predicates.at('document.type', page), {
        lang: `${locale}-nl`,
      })
      .then((response) => {
        dispatch({
          type: CONTENT_FETCH,
          [page]: response.results,
        });
      });
  };
}

export function fetchStats() {
  return {
    type: STATS_FETCH,
    payload: {
      request: {
        url: '/stats',
        method: 'GET',
      },
    },
  };
}
