import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';

// Providers
import { I18nContext } from '../../providers/I18nContextProvider';

// Styles
import textStyles from '../../styles/textStyles';

// Components
import Icon from '../icons/Icon';

const Styled = {
  Component: styled.div`
    position: relative;
  `,
  Select: styled.select`
    ${textStyles.bodyLarge};
    cursor: pointer;
    padding-right: 20px;
    background: transparent;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    :-ms-expand {
      display: none;
    }
  `,
  Icon: styled(Icon)`
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  `,
};

const LanguageSwitch = () => {
  const { langCode, dispatch } = useContext(I18nContext);
  const selectRef = useRef();

  const changeLanguage = () => {
    const lang = selectRef?.current?.value;
    dispatch({
      type: 'setLanguage',
      payload: lang,
    });
    localStorage.setItem('lang', lang);

    // change the common header in axios for all the api calls
    axios.defaults.headers.common['Accept-Language'] = lang;

    // TODO: find out a better way to reload "content"
    window.location.reload();
  };

  return (
    <Styled.Component>
      <Styled.Select ref={selectRef} defaultValue={langCode} onChange={() => changeLanguage()}>
        <option value="nl">NL</option>
        <option value="en">EN</option>
      </Styled.Select>
      <Styled.Icon icon="chevron" width={14} />
    </Styled.Component>
  );
};

export default LanguageSwitch;
