import React from 'react';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';

const Sun = ({ width, className }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 24 24"
    className={className}
  >
    <path d="M17 12a5 5 0 11-10 0 5 5 0 0110 0z" fill={colors.primary} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 15a3 3 0 100-6 3 3 0 000 6zm0 2a5 5 0 100-10 5 5 0 000 10zM21.953 12.98l-2.986-.29a7.094 7.094 0 000-1.38l2.986-.29a10.12 10.12 0 010 1.96zm-.381-3.884l-2.871.87a6.93 6.93 0 00-.525-1.264l2.645-1.417c.307.573.56 1.179.75 1.811zm-1.842-3.44L17.412 7.56a7.052 7.052 0 00-.973-.972l1.905-2.318c.506.415.97.88 1.386 1.386zm-3.015-2.477l-1.417 2.645c-.4-.215-.823-.391-1.264-.525l.87-2.87a9.936 9.936 0 011.81.75zM12.98 2.047l-.29 2.986a7.119 7.119 0 00-1.38 0l-.29-2.986a10.123 10.123 0 011.96 0zm-3.884.381l.87 2.871c-.441.134-.864.31-1.264.525L7.285 3.179a9.938 9.938 0 011.811-.75zM5.656 4.27L7.56 6.588a7.061 7.061 0 00-.972.972L4.27 5.656c.415-.506.88-.97 1.386-1.386zM3.179 7.285l2.645 1.417c-.215.4-.391.823-.525 1.264l-2.87-.87a9.938 9.938 0 01.75-1.81zM2.047 11.02a10.123 10.123 0 000 1.96l2.986-.29a7.119 7.119 0 010-1.38l-2.986-.29zm.381 3.884l2.871-.87c.134.441.31.864.525 1.264l-2.645 1.417a9.936 9.936 0 01-.75-1.811zm1.842 3.44l2.318-1.905c.291.356.617.682.972.973L5.656 19.73c-.506-.415-.97-.88-1.386-1.386zm3.015 2.477l1.417-2.645c.4.215.823.391 1.264.525l-.87 2.87a9.938 9.938 0 01-1.81-.75zm3.735 1.131l.29-2.985a7.094 7.094 0 001.38 0l.29 2.986a10.12 10.12 0 01-1.96 0zm3.884-.38a9.935 9.935 0 001.81-.751l-1.416-2.645c-.4.215-.823.391-1.264.525l.87 2.87zm3.44-1.842l-1.905-2.318c.356-.291.682-.617.973-.973l2.318 1.905c-.415.506-.88.97-1.386 1.386zm2.477-3.015l-2.645-1.417c.215-.4.391-.823.525-1.264l2.87.87a9.935 9.935 0 01-.75 1.81z"
      fill={colors.black}
    />
  </svg>
);

Sun.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

export default Sun;
