import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import axiosMiddleware from 'redux-axios-middleware';
import ReduxThunk from 'redux-thunk';
import axios from 'axios';

import reducers from './redux/index';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Providers
import I18nContextProvider from './providers/I18nContextProvider';

const headers = {};
if (localStorage.getItem('access_token')) {
  headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
}

const store = createStore(
  reducers,
  applyMiddleware(
    axiosMiddleware(
      axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        responseType: 'json',
        headers,
      })
    ),
    ReduxThunk
  )
);

ReactDOM.render(
  <React.StrictMode>
    <I18nContextProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
