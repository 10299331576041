// Global stylesheet
import { createGlobalStyle } from 'styled-components';

// Styling
import colors from './colors';
import textStyles from './textStyles';
import fonts from './fonts';
import sizes from './sizes';
import mediaQuery from './breakpoints';

const GlobalStyle = createGlobalStyle`
  /*
    @license
    MyFonts Webfont Build ID 4096838, 2021-06-01T16:30:22-0400
  */
  @font-face {
    font-family: "Gopher-Bold";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/gopher-bold-webfont.woff2') format('woff2'),
        url('/fonts/gopher-bold-webfont.woff') format('woff');
  }

  html,
  body {
    width: 100%;
    margin: 0;
    color: ${colors.black};
    background-color: ${colors.white};
    font-family: ${fonts.body};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: visible;
  }

  p {
    ${textStyles.bodyLarge};
    margin-bottom: ${sizes.space * 2}px;
  }

  h1 {
    ${textStyles.titleExtreme};
  }

  h2 {
    ${textStyles.titleLoud};
  }

  h3 {
    ${textStyles.titleNormal};
  }

  h4 {
    ${textStyles.titleSubtle};
  }

  a {
    color: ${colors.primary}
  }

  section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 20%;

    ${mediaQuery.from.M`
      padding-bottom: 15%;
    `}

    ${mediaQuery.from.L`
      padding-bottom: ${sizes.space * 15}px;
    `}

    ${mediaQuery.from.XL`
      padding-bottom: ${sizes.space * 20}px;
    `}
  }
`;

export default GlobalStyle;
