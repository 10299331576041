import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Providers
import { I18nContext } from '../providers/I18nContextProvider';

// Styles
import colors from '../styles/colors';
import sizes from '../styles/sizes';

// Components
import Icon from './icons/Icon';
import ShareButton from './elements/ShareButton';

const Styled = {
  Component: styled.div`
    padding: ${sizes.space * 5}px;
    background-color: ${colors.primaryLight};
    text-align: center;
    border-radius: ${sizes.borderRadiusImage}px;
  `,
  Icon: styled(Icon)`
    margin-bottom: ${sizes.space * 4}px;
  `,
  Title: styled.h3`
    margin-bottom: ${sizes.space * 3}px;
  `,
  Buttons: styled.div`
    display: flex;
    justify-content: center;
  `,
};

const ShareCard = ({ title, url }) => {
  const { translate } = useContext(I18nContext);
  return (
    <Styled.Component>
      <Styled.Icon icon="share" width={40} />
      <Styled.Title>{translate('app.shareCard.title')}</Styled.Title>
      <Styled.Buttons>
        <ShareButton type="email" title={title} url={url} />
        <ShareButton type="whatsapp" url={url} />
        <ShareButton type="copy" url={url} />
      </Styled.Buttons>
    </Styled.Component>
  );
};

ShareCard.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default ShareCard;
