import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from 'yup';
import find from 'lodash.find';

// Constants
import paths from '../../constants/paths';
import { buyFiltersOverview, rentFiltersOverview } from '../../constants/questionnaire';

// Providers
import { I18nContext } from '../../providers/I18nContextProvider';

// Redux
import * as citiesActions from '../../redux/actions/cities';
import * as questionnaireActions from '../../redux/actions/questionnaire';

// Components
import Seo from '../../components/Seo';
import Page from '../../components/layouts/Page';
import Container from '../../components/layouts/Container';
import Icon from '../../components/icons/Icon';
import FilterField from '../../components/FilterField';
import Stepper from '../../components/elements/FormStepper';
import Suggestions from '../../components/formFields/Suggestions';

// Styling
import colors from '../../styles/colors';
import sizes from '../../styles/sizes';
import mediaQuery from '../../styles/breakpoints';

const Styled = {
  Intro: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Icon: styled(Icon)`
    margin-bottom: 16px;

    ${mediaQuery.from.S`
      margin-bottom: 32px;
      transform: scale(1.5);
    `}

    ${mediaQuery.from.M`
      transform: scale(2);
    `}
  `,
  Title: styled.h1`
    color: ${colors.blue};
    text-align: center;
    margin-bottom: ${sizes.space * 2}px;
  `,
  Text: styled.p`
    text-align: center;
    margin-bottom: ${sizes.space * 5}px;
  `,
  FieldHolder: styled.div`
    margin-bottom: ${sizes.space * 2}px;
  `,
};

const Edit = ({ cities, questionnaire, fetchCities, storeData }) => {
  const { translate } = useContext(I18nContext);
  const router = useHistory();
  const { name } = useParams();

  const activeItem = useMemo(() => {
    let filtersOverview = buyFiltersOverview;
    if (questionnaire.listingType === 'rental') {
      filtersOverview = rentFiltersOverview;
    }
    return find(filtersOverview, { name }) || {};
  }, [name]);

  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /**
   * Set default values
   */
  useEffect(() => {
    if (questionnaire.filters) {
      Object.keys(questionnaire.filters).forEach((key) => {
        const value = questionnaire.filters[key];
        // Exception: Think of a nicer way to do this more dynamicly
        // TODO: add markup for currency default values adding € and dots
        if (value) {
          setValue(key, value);
        }
      });
    }
  }, [questionnaire.filters]);

  const handleSetValue = (fieldName, value) => {
    setValue(fieldName, value);

    // Exception: Think of a nicer way to do this more dynamicly
    if (fieldName === 'city') {
      fetchCities(value);
    }
  };

  const onSubmit = async (data) => {
    await storeData(questionnaire.listingType, {
      ...questionnaire.filters,
      ...data,
    });
    router.push(paths.questionnaireOverview);
  };

  return (
    <Page noFooter>
      <Seo title="Edit" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <section>
          <div>
            <Container size="medium">
              <Styled.Intro>
                <Styled.Icon icon={activeItem.icon} width={40} />
                <Styled.Title>{translate(activeItem.title)}</Styled.Title>
                <Styled.Text>{translate(activeItem.text)}</Styled.Text>
              </Styled.Intro>
            </Container>
            <Container size="extraSmall">
              {activeItem.fields.map((field) => (
                <Styled.FieldHolder key={field.name}>
                  <FilterField
                    register={register}
                    defaultValue={getValues(field.name) || null}
                    value={watch(field.name)}
                    field={field}
                    errors={errors}
                    setValue={handleSetValue}
                    autoCompleteValues={activeItem.name === 'location' ? cities : []} // Exception: Think of a nicer way to do this more dynamicly
                  />
                  {field.suggestions && (
                    <Suggestions
                      suggestions={field.suggestions}
                      setValue={(value) => setValue(field.name, value)}
                    />
                  )}
                </Styled.FieldHolder>
              ))}
            </Container>
          </div>
        </section>
        <Stepper
          nextLabel={translate('app.form.confirm')}
          onBackClick={() => {
            router.push(paths.questionnaireOverview);
          }}
        />
      </form>
    </Page>
  );
};

Edit.propTypes = {
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  questionnaire: PropTypes.shape({
    listingType: PropTypes.oneOf(['purchase', 'rental']).isRequired,
    activeStepIndex: PropTypes.number.isRequired,
    filters: PropTypes.shape({}),
  }).isRequired,
  fetchCities: PropTypes.func.isRequired,
  storeData: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    cities: state.cities.items,
    questionnaire: state.questionnaire,
  }),
  (dispatch) => ({
    fetchCities: (query) => dispatch(citiesActions.fetchCities(query)),
    storeData: (type, data) => dispatch(questionnaireActions.storeData(type, data)),
  })
)(Edit);
