import React, { useMemo } from 'react';
import styled, { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';

// Constants
import iconMap from '../../constants/iconMap';

// Components
import Icon from '../icons/Icon';

// Styles
import sizes from '../../styles/sizes';
import textStyles from '../../styles/textStyles';
import colors from '../../styles/colors';
import mediaQuery from '../../styles/breakpoints';

const Restyled = {
  Icon: styled(Icon)`
    transition: transform 0.2s ease-in-out;
    ${({ hasLabel }) => hasLabel && `margin-left: ${sizes.space * 2}px;`}
    ${({ rotateIcon }) => rotateIcon && `transform: rotate(180deg);`}
  `,
};

const SharedStyling = {
  button: css`
    ${textStyles.titleSubtle};
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    padding: ${sizes.space * 2}px ${sizes.space * 3}px;
    color: ${colors.black};
    border: none;
    border-radius: 80px;
    box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.16);
    transition: box-shadow 0.2s ease-out, padding-left 0.2s ease-out;
    ${({ disabled }) => disabled && 'opacity: 0.5;'}

    ${mediaQuery.from.S`
      padding: 24px 32px;
    `}

    :hover {
      ${Restyled.Icon} {
        transform: ${({ rotateIcon }) =>
          rotateIcon ? 'rotate(180deg) translateX(8px)' : 'translateX(8px)'};

        ${mediaQuery.from.S`
          transform: ${({ rotateIcon }) =>
            rotateIcon ? 'rotate(180deg) translateX(12px)' : 'translateX(12px)'};
        `}
      }
    }

    :focus {
      outline: none;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    }
  `,
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Styled = {
  Link: styled.a`
    ${SharedStyling.button};
    text-decoration: none;
    background-color: ${colors.white};
  `,

  Primary: styled.button`
    ${SharedStyling.button};
    background-color: ${colors.secondary};

    &.loading {
      padding-left: 50px;
    }
  `,

  Secondary: styled.button`
    ${SharedStyling.button};
    background-color: ${colors.white};

    &.loading {
      padding-left: 50px;
    }
  `,

  Loader: styled.span`
    position: absolute;
    left: ${sizes.space * 2}px;
    top: 50%;
    height: 20px;
    width: 20px;
    margin-top: -10px;
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 50%;
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    border-bottom: 3px solid #fff;
    border-right: 3px solid rgba(255, 255, 255, 0.3);
    animation: ${rotate} 0.75s linear infinite;
    transition: all 0.5s ease;
    opacity: 0;

    &.shown {
      opacity: 1;
    }
  `,
};

const Button = ({ label, version, type, onClick, icon, rotateIcon, disabled, loading, href }) => {
  const loader = useMemo(() => <Styled.Loader className={loading ? 'shown' : ''} />, [loading]);
  const arrow = useMemo(
    () => (
      <Restyled.Icon
        icon={icon}
        color={colors.black}
        width={25}
        hasLabel={!!label}
        rotateIcon={rotateIcon}
      />
    ),
    [label, rotateIcon]
  );

  if (type === 'phone') {
    return (
      <Styled.Link href={`tel:${href}`}>
        {label}
        <Restyled.Icon
          icon={icon}
          color={colors.black}
          width={16}
          hasLabel={!!label}
          rotateIcon={rotateIcon}
        />
      </Styled.Link>
    );
  }

  if (version === 'secondary') {
    return (
      <Styled.Secondary
        type={type}
        onClick={() => {
          if (disabled) return;
          onClick();
        }}
        rotateIcon={rotateIcon}
        disabled={disabled}
        className={loading ? 'loading' : ''}
      >
        {loader}
        {label}
        {arrow}
      </Styled.Secondary>
    );
  }

  return (
    <Styled.Primary
      type={type}
      onClick={() => {
        if (disabled) return;
        onClick();
      }}
      rotateIcon={rotateIcon}
      disabled={disabled}
      className={loading ? 'loading' : ''}
    >
      {loader}
      {label}
      {arrow}
    </Styled.Primary>
  );
};

Button.propTypes = {
  label: PropTypes.string,
  version: PropTypes.oneOf(['primary', 'secondary']),
  type: PropTypes.oneOf(['button', 'submit', 'phone']),
  onClick: PropTypes.func,
  icon: PropTypes.oneOf(Object.keys(iconMap)),
  rotateIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  href: PropTypes.string,
};

Button.defaultProps = {
  label: '',
  version: 'primary',
  type: 'button',
  onClick: null,
  icon: 'arrow',
  rotateIcon: false,
  disabled: false,
  loading: false,
  href: '',
};

export default Button;
