import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Styles
import sizes from '../../styles/sizes';

// Components
import SuggestionButton from '../elements/SuggestionButton';

const Styled = {
  Component: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: ${sizes.space * 3}px;

    > button {
      margin-top: ${sizes.space * 2}px;
      margin-right: ${sizes.space * 1}px;
      margin-left: ${sizes.space * 1}px;
    }
  `,
};

const Suggestions = ({ suggestions, setValue }) => (
  <Styled.Component>
    {suggestions.map((suggestion) => (
      <SuggestionButton
        key={`suggestion-${suggestion.label}`}
        label={suggestion.label}
        onClick={() => setValue(suggestion.value)}
      />
    ))}
  </Styled.Component>
);

Suggestions.propTypes = {
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  setValue: PropTypes.func.isRequired,
};

export default Suggestions;
