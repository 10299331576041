import { combineReducers } from 'redux';

import content from './reducers/content';
import listing from './reducers/listing';
import cities from './reducers/cities';
import questionnaire from './reducers/questionnaire';
import flashNotifications from './reducers/flash-notifications';

const reducers = combineReducers({
  content,
  listing,
  cities,
  questionnaire,
  flashNotifications,
});

export default reducers;
