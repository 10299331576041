import React, { useRef, useEffect, useState, forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';
import textStyles from '../../styles/textStyles';
import sizes from '../../styles/sizes';

// Components
import Error from '../elements/Error';
import Icon from '../icons/Icon';

const Styled = {
  Label: styled.label`
    ${textStyles.bodySmall};
    margin-bottom: ${sizes.space / 2}px;
    font-weight: 700;
  `,
  InputWrapper: styled.div`
    position: relative;
  `,
  Input: styled.input`
    ${textStyles.titleSubtle};
    position: relative;
    width: 100%;
    padding: ${sizes.space * 2}px ${sizes.space * 3}px;
    border: 2px solid ${colors.black};
    border-radius: 8px;
    outline: none;
    transition: border-color 200ms;
    ${({ disabled }) => disabled && 'opacity: 0.5;'}
    ${({ hasError }) =>
      hasError
        ? `border-color: ${colors.error};`
        : `&:focus {
            border-color: ${colors.primary};
          }`}

    &::placeholder {
      color: ${colors.grey};
      opacity: 1;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }

    &::-webkit-calendar-picker-indicator {
      display: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  `,
  Validated: styled(Icon)`
    z-index: 1;
    position: absolute;
    top: 50%;
    right: ${sizes.space * 2}px;
    transform: translateY(-50%);
  `,
  Reset: styled.div`
    cursor: pointer;
    z-index: 1;
    display: flex;
    position: absolute;
    top: 50%;
    right: calc(${sizes.space * 3}px - 4px);
    transform: translateY(-50%);
    padding: 4px;
    border: none;
    background-color: transparent;
  `,
};

const useCombinedRefs = (...refs) => {
  const targetRef = useRef();

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        const newRef = { ...ref };
        newRef.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

const TextField = forwardRef(
  (
    {
      name,
      value,
      clearValue,
      label,
      placeholder,
      type,
      setFocus,
      onChange,
      error,
      warning,
      defaultVal,
    },
    ref
  ) => {
    const fieldRef = useRef(null);
    const combinedRef = useCombinedRefs(ref, fieldRef);

    const [hasFocus, setHasFocus] = useState(false);

    const clearValues = (event) => {
      event.preventDefault();
      const inputElement = combinedRef?.current;
      inputElement.value = '';
      clearValue();
    };

    useEffect(() => {
      if (setFocus) combinedRef?.current?.focus();
    }, [setFocus]);

    return (
      <>
        <Styled.Label htmlFor={name}>{label}</Styled.Label>
        <Styled.InputWrapper>
          {!!value.length && !hasFocus && <Styled.Validated icon="validated" width={32} />}
          {!!value.length && hasFocus && (
            <Styled.Reset onMouseDown={(event) => clearValues(event)}>
              <Icon icon="cross" width={16} />
            </Styled.Reset>
          )}
          <Styled.Input
            name={name}
            ref={combinedRef}
            type={type}
            placeholder={placeholder}
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
            hasError={!!error}
            onChange={onChange}
            defaultValue={defaultVal}
          />
        </Styled.InputWrapper>
        {(!!error || !!warning) && (
          <Error type={error ? 'error' : 'warning'} label={error || warning} />
        )}
      </>
    );
  }
);

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clearValue: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'email', 'password', 'hidden']),
  setFocus: PropTypes.bool,
  warning: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultVal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TextField.defaultProps = {
  value: '',
  label: false,
  placeholder: '',
  type: 'text',
  setFocus: false,
  warning: '',
  error: '',
  defaultVal: '',
};

export default TextField;
