import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Styles
import colors from '../../styles/colors';

const Styled = {
  Path: styled.path`
    tranition: fill 0.2s ease-in-out;
  `,
};

const WhatsApp = ({ width, className, color }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 24 24"
    className={className}
  >
    <Styled.Path
      d="M.055 24l1.687-6.163a11.867 11.867 0 01-1.587-5.946C.158 5.335 5.493 0 12.048 0a11.817 11.817 0 018.413 3.488 11.824 11.824 0 013.48 8.414c-.003 6.557-5.338 11.892-11.893 11.892a11.9 11.9 0 01-5.688-1.448L.055 24zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.89 9.884 0 2.225.652 3.891 1.747 5.634l-1 3.648 3.743-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.67.149-.197.297-.767.967-.94 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.15-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.67-1.611-.916-2.206-.242-.579-.487-.501-.67-.51l-.57-.01c-.197 0-.52.074-.791.372-.272.298-1.04 1.016-1.04 2.479s1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.57-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"
      fill={color}
    />
  </svg>
);

WhatsApp.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  color: PropTypes.string,
};

WhatsApp.defaultProps = {
  color: colors.black,
};

export default WhatsApp;
