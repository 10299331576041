import React from 'react';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';

const Sun = ({ width, color, className }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 17 24"
    className={className}
  >
    <path
      d="M2.676 1.322L5.52 0l4.041 7.89-2.724 1.341c-.538 1.259 2.16 6.289 3.297 6.372.09-.058 2.671-1.328 2.671-1.328l4.11 7.932s-2.764 1.354-2.854 1.396C6.2 27.194-5.042 5.345 2.676 1.322zm1.93 1.274L3.581 3.1C-1.712 5.862 7.76 24.285 13.23 21.786l.971-.474-2.27-4.383-1.027.5C7.741 18.976 2.642 9.21 5.85 7.491l1.007-.497-2.25-4.398z"
      fill={color ?? colors.black}
    />
  </svg>
);

Sun.propTypes = {
  width: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default Sun;
