import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// Constants
import paths from '../constants/paths';

// Components
import Container from './layouts/Container';
import Button from './elements/Button';

// Styles
import colors from '../styles/colors';
import textStyles from '../styles/textStyles';
import mediaQuery from '../styles/breakpoints';
import sizes from '../styles/sizes';

const Styled = {
  Container: styled.div`
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${sizes.space * 4}px ${sizes.space * 2}px;
    background-color: ${colors.secondary};
    border-radius: ${sizes.borderRadius}px;

    ${mediaQuery.from.S`
      padding: ${sizes.space * 6}px;
    `}
  `,

  Title: styled.h4`
    ${textStyles.titleNormal};
    text-align: center;
    margin-bottom: ${sizes.space * 4}px;
  `,

  ButtonsWrapper: styled.div`
    display: flex;
    flex-direction: column;

    > button:first-of-type {
      margin-bottom: ${sizes.space * 4}px;
    }

    ${mediaQuery.from.S`
      flex-direction: row;

      > button:first-of-type {
        margin-bottom: 0;
        margin-right: ${sizes.space * 4}px;
      }
    `}
  `,
};

const Cta = ({ title, buyLabel, rentLabel }) => {
  const router = useHistory();
  return (
    <Container size="medium">
      <Styled.Container>
        <Styled.Title>{title}</Styled.Title>
        <Styled.ButtonsWrapper>
          <Button
            label={buyLabel}
            version="secondary"
            onClick={() => router.push(paths.questionnaireBuy)}
          />
          <Button
            label={rentLabel}
            version="secondary"
            onClick={() => router.push(paths.questionnaireRent)}
          />
        </Styled.ButtonsWrapper>
      </Styled.Container>
    </Container>
  );
};

Cta.propTypes = {
  title: PropTypes.string.isRequired,
  buyLabel: PropTypes.string.isRequired,
  rentLabel: PropTypes.string.isRequired,
};

export default Cta;
