export const FLASH_NOTIFICATIONS = 'FLASH_NOTIFICATIONS';

/**
 * Add notifications to flash module
 * @param {Array} notifications
 */
export function addFlashNotifications(notifications) {
  if (!notifications || notifications.length === 0) return null;

  return {
    type: FLASH_NOTIFICATIONS,
    notifications,
  };
}
