import React, { useRef, useEffect, useState, forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';
import textStyles from '../../styles/textStyles';
import sizes from '../../styles/sizes';

// Components
import Error from '../elements/Error';
import Icon from '../icons/Icon';

const Styled = styled.label`
  ${textStyles.bodySmall};
  margin-bottom: ${sizes.space / 2}px;
  font-weight: 700;
`;

const StyledInputWrapper = styled.div`
  position: relative;
  z-index: 3;
`;

const StyledInput = styled.input`
  ${textStyles.titleSubtle};
  position: relative;
  width: 100%;
  padding: ${sizes.space * 2}px ${sizes.space * 3}px;
  border: 2px solid ${colors.black};
  border-radius: 8px;
  outline: none;
  transition: border-color 200ms;
  ${({ disabled }) => disabled && 'opacity: 0.5;'}
  ${({ hasError }) =>
    hasError
      ? `border-color: ${colors.error};`
      : `&:focus {
            border-color: ${colors.primary};
          }`}

    &::placeholder {
    color: ${colors.grey};
    opacity: 1;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-calendar-picker-indicator {
    display: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledValidated = styled(Icon)`
  z-index: 1;
  position: absolute;
  top: 50%;
  right: ${sizes.space * 2}px;
  transform: translateY(-50%);
`;

const StyledReset = styled.div`
  cursor: pointer;
  z-index: 1;
  display: flex;
  position: absolute;
  top: 50%;
  right: calc(${sizes.space * 3}px - 4px);
  transform: translateY(-50%);
  padding: 4px;
  border: none;
  background-color: transparent;
`;

const StyledDropDownWrapper = styled.div`
  position: relative;
  z-index: 2;
`;

const StyledDropDown = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -2px;
  width: 100%;
  background-color: ${colors.white};
  border-radius: 8px;
  overflow: hidden;
  ${({ active }) => active && `border: 2px solid ${colors.primary}`};
`;

const StyledComplete = styled.div`
  ${textStyles.titleSubtle};
  cursor: pointer;
  width: 100%;
  text-align: left;
  padding: ${sizes.space * 2}px ${sizes.space * 3}px;
  border: none;
  background-color: transparent;

  :hover {
    background-color: ${colors.primaryLight};
  }
`;

function useCombinedRefs(...refs) {
  const targetRef = useRef();

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        const newRef = { ...ref };
        newRef.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}

const AutocompleteField = forwardRef(
  (
    {
      name,
      value,
      clearValue,
      setValue,
      label,
      placeholder,
      type,
      setFocus,
      onChange,
      error,
      warning,
      defaultVal,
      autoCompleteValues,
    },
    ref
  ) => {
    const fieldRef = useRef(null);
    const combinedRef = useCombinedRefs(ref, fieldRef);

    const [inputValue, setInputValue] = useState('');
    const [hasFocus, setHasFocus] = useState(false);
    const [completionValues, setCompletionValues] = useState([]);

    const clearValues = (event) => {
      event.preventDefault();
      const inputElement = combinedRef?.current;
      inputElement.value = '';
      setInputValue('');
      clearValue();
    };

    const populateAutoComplete = () => {
      const completionValuesArray = [];
      if (autoCompleteValues.length > 0 && typeof value === 'string') {
        autoCompleteValues.forEach((autoCompleteItem) => {
          if (autoCompleteItem.name.toLowerCase().startsWith(value.toLowerCase())) {
            completionValuesArray.push(autoCompleteItem);
          } else {
            setCompletionValues([]);
          }
        });
      }
      setCompletionValues(completionValuesArray);
    };

    const handleAutoComplete = (event, item) => {
      event.preventDefault();
      setValue(item);
      setInputValue(item.name);
      combinedRef?.current?.blur();
    };

    useEffect(() => {
      if (setFocus) combinedRef?.current?.focus();
    }, [setFocus]);

    useEffect(() => {
      populateAutoComplete();
    }, [value]);

    return (
      <>
        <Styled htmlFor={name}>{label}</Styled>
        <StyledInputWrapper>
          {!!value.length && !hasFocus && <StyledValidated icon="validated" width={32} />}
          {!!value.length && hasFocus && (
            <StyledReset onMouseDown={(event) => clearValues(event)}>
              <Icon icon="cross" width={16} />
            </StyledReset>
          )}
          <StyledInput
            name={name}
            ref={combinedRef}
            type={type}
            placeholder={placeholder}
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
            hasError={!!error}
            onChange={(e) => {
              setInputValue(e.target.value);
              onChange(e.target.value);
            }}
            defaultValue={defaultVal}
            value={inputValue}
            autoComplete="off"
          />
        </StyledInputWrapper>
        <StyledDropDownWrapper>
          <StyledDropDown active={!!completionValues.length && hasFocus}>
            {completionValues.map((item) => (
              <div key={`auto-complete-${item.value}`}>
                {hasFocus && (
                  <StyledComplete onMouseDown={(event) => handleAutoComplete(event, item)}>
                    {item.name}
                  </StyledComplete>
                )}
              </div>
            ))}
          </StyledDropDown>
        </StyledDropDownWrapper>
        {(!!error || !!warning) && (
          <Error type={error ? 'error' : 'warning'} label={error || warning} />
        )}
      </>
    );
  }
);

AutocompleteField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({})]),
  clearValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  autoCompleteValues: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'email', 'password', 'hidden']),
  setFocus: PropTypes.bool,
  warning: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultVal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AutocompleteField.defaultProps = {
  value: '',
  autoCompleteValues: [],
  label: false,
  placeholder: '',
  type: 'text',
  setFocus: false,
  warning: '',
  error: '',
  defaultVal: '',
};

export default AutocompleteField;
