import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Constants
import paths from '../constants/paths';

// Providers
import { I18nContext } from '../providers/I18nContextProvider';

// Components
import Logo from './assets/Logo';

// Styles
import colors from '../styles/colors';
import sizes from '../styles/sizes';
import mediaQuery from '../styles/breakpoints';

const StyledFooter = styled.footer`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;

  ${mediaQuery.from.S`
      flex-direction: row-reverse;
      align-items: flex-end;
    `}
`;

const StyledLogo = styled(Logo)`
  width: 300px;
  margin-left: -47px;
  margin-bottom: -74px;

  ${mediaQuery.from.S`
      width: 450px;
      margin-top: ${sizes.space * 8}px;
      margin-left: -70px;
      margin-bottom: -108px;
    `},
`;

const StyledMenu = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: ${sizes.space * 3}px;

  ${mediaQuery.from.S`
      width: auto;
    `},

  li {
    margin: 0;
  }
`;

const StyledLink = styled(Link)`
  color: ${colors.black};
  text-decoration: none;
  display: block;
  padding: ${sizes.space}px;
  font-size: 14px;
  line-height: 1.6;
  transition: opacity 200ms ease-out;

  ${mediaQuery.from.S`
      padding: ${sizes.space * 2}px;
    `},

  &:hover {
    opacity: 0.5;
  }
`;

const Footer = () => {
  const { translate } = useContext(I18nContext);
  return (
    <StyledFooter>
      <StyledMenu>
        <li>
          <StyledLink to={paths.privacy}>{translate('app.footer.menu.privacy')}</StyledLink>
        </li>
        <li>
          <StyledLink to={paths.disclaimer}>{translate('app.footer.menu.disclaimer')}</StyledLink>
        </li>
        <li>
          <StyledLink to={paths.contact}>{translate('app.footer.menu.contact')}</StyledLink>
        </li>
      </StyledMenu>
      <Link to={paths.home}>
        <StyledLogo />
      </Link>
    </StyledFooter>
  );
};

export default Footer;
