import React from 'react';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';

const Bricks = ({ width, className }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 24 24"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 10H3v4h4v-4zM1 8v8h8V8H1z"
      fill={colors.black}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 10H9v4h8v-4zM7 8v8h12V8H7z"
      fill={colors.black}
    />
    <path d="M1 2h12v8H1V2z" fill={colors.primary} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 4H3v4h8V4zM1 2v8h12V2H1zM11 16H3v4h8v-4zM1 14v8h12v-8H1z"
      fill={colors.black}
    />
    <path d="M11 14h12v8H11v-8z" fill={colors.primary} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 16h-8v4h8v-4zm-10-2v8h12v-8H11z"
      fill={colors.black}
    />
  </svg>
);

Bricks.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

export default Bricks;
