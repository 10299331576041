import React from 'react';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';

const Share = ({ width, className }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 40 40"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.255 8.51l1.49 2.981-17.018 8.51 17.019 8.509-1.491 2.981-22.982-11.49L29.255 8.51z"
      fill={colors.black}
    />
    <path
      d="M36.665 10a6.667 6.667 0 11-13.333 0 6.667 6.667 0 0113.333 0z"
      fill={colors.primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.999 13.334a3.333 3.333 0 100-6.667 3.333 3.333 0 000 6.667zm0 3.333a6.667 6.667 0 100-13.333 6.667 6.667 0 000 13.333z"
      fill={colors.black}
    />
    <path
      d="M36.665 30a6.667 6.667 0 11-13.333 0 6.667 6.667 0 0113.333 0z"
      fill={colors.primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.999 33.334a3.333 3.333 0 100-6.667 3.333 3.333 0 000 6.667zm0 3.333a6.667 6.667 0 100-13.333 6.667 6.667 0 000 13.333z"
      fill={colors.black}
    />
    <path
      d="M16.665 20a6.667 6.667 0 11-13.333 0 6.667 6.667 0 0113.333 0z"
      fill={colors.primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.999 23.334a3.333 3.333 0 100-6.667 3.333 3.333 0 000 6.667zm0 3.333a6.667 6.667 0 100-13.333 6.667 6.667 0 000 13.333z"
      fill={colors.black}
    />
  </svg>
);

Share.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

export default Share;
