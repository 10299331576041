import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Constants
import iconMap from '../constants/iconMap';

// Styles
import colors from '../styles/colors';
import textStyles from '../styles/textStyles';
import sizes from '../styles/sizes';

// Components
import Icon from './icons/Icon';

const StyledContainer = styled.div`
  margin-bottom: ${sizes.space * 4}px;
  padding-top: ${sizes.space}px;
  border-top: solid 2px ${colors.black};
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${sizes.space * 2}px;
`;

const StyledTitle = styled.h4`
  ${textStyles.titleNormal};
`;

const StyledIcon = styled(Icon)`
  margin-right: ${sizes.space}px;
  max-height: ${sizes.space * 3}px;
`;

const StyledContent = styled.div`
  padding-left: ${sizes.space * 4}px;
`;

const StyledLabel = styled.span`
  ${textStyles.bodySmall};
  margin-bottom: ${sizes.space / 2}px;
`;

const StyledValue = styled.h4`
  ${textStyles.titleSubtle};
  margin-bottom: ${sizes.space * 3}px;
`;

const ListingDetailSection = ({ title, icon, items }) => (
  <StyledContainer>
    <StyledTitleWrapper>
      <StyledIcon icon={icon} width={sizes.space * 3} />
      <StyledTitle>{title}</StyledTitle>
    </StyledTitleWrapper>
    <StyledContent>
      {items.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`${item.label}-${item.value}-${i}`}>
          <StyledLabel>{item.label}</StyledLabel>
          <StyledValue>{item.value}</StyledValue>
        </div>
      ))}
    </StyledContent>
  </StyledContainer>
);

ListingDetailSection.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(Object.keys(iconMap)).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
};

export default ListingDetailSection;
