import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Styles
import colors from '../styles/colors';
import sizes from '../styles/sizes';

// Components
import Icon from './icons/Icon';
import Button from './elements/Button';

const StyledComponent = styled.div`
  padding: ${sizes.space * 5}px;
  color: ${colors.white};
  background-color: ${colors.primary};
  text-align: center;
  border-radius: ${sizes.borderRadiusImage}px;
`;

const StyledIcon = styled(Icon)`
  margin-bottom: ${sizes.space * 4}px;
`;

const StyledTitle = styled.h3`
  margin-bottom: ${sizes.space * 3}px;
`;

const StyledLink = styled.a`
  font-size: 18px;
  color: ${colors.white};
`;

const RealtorCard = ({ name, phone, website }) => {
  const phoneIsValid = /(?:\D*\d){3}/.test(phone);

  return (
    <StyledComponent>
      <StyledIcon icon="houses" width={40} />
      <StyledTitle>{name}</StyledTitle>
      {phoneIsValid ? (
        <Button label={phone} type="phone" icon="phone" href={phone} />
      ) : (
        <StyledLink href={website} target="_blank" rel="noopener noreferrer nofollow">
          {website.replace('http://', '').replace('https://', '').replace('www.', '')}
        </StyledLink>
      )}
    </StyledComponent>
  );
};

RealtorCard.propTypes = {
  name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  website: PropTypes.string.isRequired,
};

export default RealtorCard;
