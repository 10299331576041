import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Styles
import colors from '../../styles/colors';

const Styled = {
  Path: styled.path`
    tranition: fill 0.2s ease-in-out;
  `,
};

const Copy = ({ width, className, color }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 18 22"
    className={className}
  >
    <Styled.Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 2h13v17h2V0H3v2zM2 6h10v14H2V6zM0 22V4h14v18H0z"
      fill={color}
    />
  </svg>
);

Copy.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  color: PropTypes.string,
};

Copy.defaultProps = {
  color: colors.black,
};

export default Copy;
