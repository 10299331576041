import React from 'react';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';

const Distance = ({ width, className }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 24 24"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 6L7 18l-1.414-1.414 12-12L19 6z"
      fill={colors.black}
    />
    <path d="M12 18a6 6 0 11-12 0 6 6 0 0112 0z" fill={colors.primary} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 22a4 4 0 100-8 4 4 0 000 8zm0 2a6 6 0 100-12 6 6 0 000 12zM20 6a2 2 0 100-4 2 2 0 000 4zm0 2a4 4 0 100-8 4 4 0 000 8z"
      fill={colors.black}
    />
  </svg>
);

Distance.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

export default Distance;
