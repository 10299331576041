import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// styles
import colors from '../../styles/colors';

const width = 30;
const height = 26;
const lineSize = 4;
const Styled = {
  Component: styled.button`
    position: relative;
    width: ${width}px;
    height: ${height}px;
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  `,
  Slice: styled.span`
    position: absolute;
    display: block;
    height: ${lineSize}px;
    width: ${width}px;
    background-color: ${colors.black};
    transition: all 0.2s ease-out;

    &:nth-child(1) {
      margin-top: ${(props) => (props.open ? `-${lineSize}px` : 0)};
      top: ${(props) => (props.open ? '50%' : 0)};
      transform: ${(props) => (props.open ? 'rotate(45deg)' : 'rotate(0deg)')};
    }

    &:nth-child(2) {
      top: 50%;
      margin-top: -${lineSize / 2}px;
      opacity: ${(props) => (props.open ? 0 : 1)};
    }

    &:nth-child(3) {
      top: auto;
      margin-top: ${(props) => (props.open ? `-${lineSize}px` : 0)};
      bottom: ${(props) => (props.open ? '50%' : 0)};
      transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'rotate(0deg)')};
    }
  `,
};

const Burger = ({ open, onClick }) => (
  <Styled.Component onClick={onClick}>
    <Styled.Slice open={open} />
    <Styled.Slice open={open} />
    <Styled.Slice open={open} />
  </Styled.Component>
);

Burger.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

Burger.defaultProps = {
  open: false,
};

export default Burger;
