import React from 'react';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';

const Houses = ({ width, className }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 40 40"
    className={className}
  >
    <path d="M13.332 40V10L26.665 0 40 10v30H13.332z" fill={colors.white} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.665 11.667v25h20v-25l-10-7.5-10 7.5zM13.332 10v30h26.667V10L26.665 0 13.332 10z"
      fill={colors.black}
    />
    <path d="M20 23.334h13.333v16.667H20V23.334z" fill={colors.primary} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 26.667h-6.667v10H30v-10zm-10-3.333v16.667h13.333V23.334H20z"
      fill={colors.black}
    />
    <path d="M31.668 15a5 5 0 11-10 0 5 5 0 0110 0z" fill={colors.primary} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.668 16.667a1.667 1.667 0 100-3.334 1.667 1.667 0 000 3.334zm0 3.333a5 5 0 100-10 5 5 0 000 10z"
      fill={colors.black}
    />
    <path d="M0 40V10L13.333 0l13.334 10v30H0z" fill={colors.white} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.333 11.667v25h20v-25l-10-7.5-10 7.5zM0 10v30h26.667V10L13.333 0 0 10z"
      fill={colors.black}
    />
    <path d="M6.668 23.334h13.333v16.667H6.668V23.334z" fill={colors.primary} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.668 26.667h-6.667v10h6.667v-10zm-10-3.333v16.667h13.333V23.334H6.668z"
      fill={colors.black}
    />
    <path d="M18.332 15a5 5 0 11-10 0 5 5 0 0110 0z" fill={colors.primary} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.332 16.667a1.667 1.667 0 100-3.334 1.667 1.667 0 000 3.334zm0 3.333a5 5 0 100-10 5 5 0 000 10z"
      fill={colors.black}
    />
  </svg>
);

Houses.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

export default Houses;
