import React from 'react';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';

const Door = ({ width, className }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 24 24"
    className={className}
  >
    <path fill={colors.white} d="M5 3h14v18H5z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 4H6v16h12V4zM4 2v20h16V2H4z"
      fill={colors.black}
    />
    <path d="M0 20h24v2H0v-2z" fill={colors.black} />
    <path d="M8 6h8v6H8V6z" fill={colors.primary} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 8h-4v2h4V8zM8 6v6h8V6H8z"
      fill={colors.black}
    />
    <path d="M12 14h4v2h-4v-2z" fill={colors.black} />
  </svg>
);

Door.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

export default Door;
