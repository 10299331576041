import React from 'react';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';

const HouseApartment = ({ width, className }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 24 24"
    className={className}
  >
    <path d="M10 0h14v24H10V0z" fill={colors.white} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 2H12v20h10V2zM10 0v24h14V0H10z"
      fill={colors.black}
    />
    <path d="M14 4h6v6h-6V4z" fill={colors.primary} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 6h-2v2h2V6zm-4-2v6h6V4h-6z"
      fill={colors.black}
    />
    <path d="M14 12h6v6h-6v-6z" fill={colors.primary} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 14h-2v2h2v-2zm-4-2v6h6v-6h-6z"
      fill={colors.black}
    />
    <path d="M0 24V12l8-6 8 6v12H0z" fill={colors.white} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 13v9h12v-9L8 8.5 2 13zm-2-1v12h16V12L8 6l-8 6z"
      fill={colors.black}
    />
    <path d="M5 16h6v8H5v-8z" fill={colors.primary} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 18H7v4h2v-4zm-4-2v8h6v-8H5z"
      fill={colors.black}
    />
  </svg>
);

HouseApartment.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

export default HouseApartment;
