import React, { useContext, useEffect, useState, useRef, createRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Providers
import { I18nContext } from '../providers/I18nContextProvider';

// Redux
import * as contentActions from '../redux/actions/content';

// Components
import Seo from '../components/Seo';
import Page from '../components/layouts/Page';
import Loader from '../components/elements/Loader';
import Container from '../components/layouts/Container';

// Styling
import sizes from '../styles/sizes';
import colors from '../styles/colors';
import textStyles from '../styles/textStyles';

const Styled = {
  Title: styled.h1`
    margin-bottom: ${sizes.space * 3}px;
  `,
  Item: styled.div`
    overflow: hidden;
    padding: ${sizes.space * 1}px;
    border-radius: 8px;
    margin-bottom: ${sizes.space * 2}px;
    background-color: ${colors.primaryLight};
    transition: height 0.2s ease-in-out;
  `,
  Question: styled.h2`
    ${textStyles.titleSubtle};
    cursor: pointer;
    position: relative;
    display: block;
    margin-bottom: 0;
    padding: ${sizes.space * 3}px ${sizes.space * 8}px ${sizes.space * 3}px ${sizes.space * 3}px;
    transition: color 0.2s ease-in-out;

    :hover {
      color: ${colors.primary};
    }

    :before,
    :after {
      content: '';
      position: absolute;
      top: 50%;
      right: ${sizes.space * 3}px;
      width: 24px;
      height: 3px;
      background-color: ${colors.black};
      border-radius: 4px;
    }

    :before {
      transform: translateY(-50%);
    }

    :after {
      transform: translateY(-50%) rotate(90deg);
    }

    ${({ active }) =>
      active &&
      `
      :after {
        opacity: 0;
      }
    `}
  `,

  Text: styled.div`
    box-sizing: border-box;
    height: 0;
    opacity: 0;
    padding-left: ${sizes.space * 3}px;
    padding-right: ${sizes.space * 4}px;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out, height 0.2s ease-in-out, margin-bottom 0.2s ease-in-out;

    ${({ active }) =>
      active &&
      `
      margin-bottom: ${sizes.space * 3}px;
      opacity: 1;
      pointer-events: default;
    `}
  `,
};

const Faq = ({ fetchContent, content }) => {
  const { langCode } = useContext(I18nContext);

  useEffect(() => {
    fetchContent(langCode, 'faq');
  }, []);

  const [activeItems, setActiveItems] = useState([]);
  const [accordionDataLoaded, setAccordionDataLoaded] = useState(false);

  const refs = useRef([]);

  useEffect(() => {
    if (content?.faq?.accordion.length) {
      refs.current = content?.faq?.accordion?.map(
        // eslint-disable-next-line no-return-assign
        (_ref, index) => (refs.current[index] = createRef())
      );
      setAccordionDataLoaded(true);
    }
  }, [content?.faq?.accordion]);

  const handleOpenItem = (i) => {
    const element = refs?.current[i]?.current;
    if (activeItems.includes(i)) {
      setActiveItems((oldState) => {
        const newState = oldState.filter((item) => item !== i);
        return newState;
      });
      element.style.height = '0px';
    } else {
      setActiveItems((oldState) => [...oldState, i]);
      element.style.height = `${element.scrollHeight}px`;
    }
  };

  return (
    <Page>
      <Seo title={content.faq?.meta.title} description={content.faq?.meta.description} />
      <Container size="small">
        <Styled.Title>{content?.faq?.title}</Styled.Title>
        {accordionDataLoaded ? (
          <>
            {content?.faq?.accordion.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Styled.Item key={`faq-${i}`} active={activeItems.includes(i)}>
                <Styled.Question onClick={() => handleOpenItem(i)} active={activeItems.includes(i)}>
                  {item.question}
                </Styled.Question>
                <Styled.Text
                  dangerouslySetInnerHTML={{ __html: item.answer }}
                  ref={refs.current[i]}
                  active={activeItems.includes(i)}
                />
              </Styled.Item>
            ))}
          </>
        ) : (
          <Loader />
        )}
      </Container>
    </Page>
  );
};

Faq.propTypes = {
  fetchContent: PropTypes.func.isRequired,
  content: PropTypes.shape({
    faq: PropTypes.shape({
      meta: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
      title: PropTypes.string,
      accordion: PropTypes.arrayOf(
        PropTypes.shape({
          question: PropTypes.string,
          answer: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
};

export default connect(
  (state) => ({
    content: state.content,
  }),
  (dispatch) => ({
    fetchContent: (locale, page) => dispatch(contentActions.fetchContent(locale, page)),
  })
)(Faq);
