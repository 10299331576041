import { css } from 'styled-components';

// these sizes are arbitrary and you can set them to whatever you wish
const sizes = {
  XL: 1440,
  L: 1280,
  M: 1024,
  S: 768,
  XS: 600,
  XXS: 376,
};

// iterate through the sizes and create a media template
const from = Object.keys(sizes).reduce(
  (mediaQueriesObject, breakPointLabel) => {
    // use em in breakpoints to work properly cross-browser and support users
    // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
    const emSize = sizes[breakPointLabel] / 16;

    // eslint-disable-next-line no-param-reassign
    mediaQueriesObject[breakPointLabel] = (cssStrings, breakpointSize) => css`
      @media (min-width: ${emSize}em) {
        ${css(cssStrings, breakpointSize)}
      }
    `;
    return mediaQueriesObject;
  },
  {
    px: (px) => (cssStrings, breakpointSize) =>
      css`
        @media (min-width: ${px}px) {
          ${css(cssStrings, breakpointSize)}
        }
      `,
  }
);

// iterate through the sizes and create a media template
const to = Object.keys(sizes).reduce(
  (mediaQueriesObject, breakPointLabel) => {
    // use em in breakpoints to work properly cross-browser and support users
    // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
    const emSize = sizes[breakPointLabel] / 16;

    // eslint-disable-next-line no-param-reassign
    mediaQueriesObject[breakPointLabel] = (cssStrings, breakpointSize) => css`
      @media (max-width: ${emSize}em) {
        ${css(cssStrings, breakpointSize)}
      }
    `;
    return mediaQueriesObject;
  },
  {
    px: (px) => (cssStrings, breakpointSize) =>
      css`
        @media (max-width: ${px}px) {
          ${css(cssStrings, breakpointSize)}
        }
      `,
  }
);

const mediaQueries = {
  from,
  to,
};

export { sizes };
export default mediaQueries;
