import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// Constants
import paths from '../../constants/paths';

// Providers
import { I18nContext } from '../../providers/I18nContextProvider';

// Redux
import * as questionnaireActions from '../../redux/actions/questionnaire';
import * as flashNotificationsActions from '../../redux/actions/flash-notifications';

// Components
import Seo from '../../components/Seo';
import Page from '../../components/layouts/Page';
import Container from '../../components/layouts/Container';
import Icon from '../../components/icons/Icon';
import TextField from '../../components/formFields/TextField';
import Stepper from '../../components/elements/FormStepper';

// Styling
import mediaQuery from '../../styles/breakpoints';
import colors from '../../styles/colors';
import sizes from '../../styles/sizes';

const Styled = {
  Intro: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Icon: styled(Icon)`
    margin-bottom: 16px;

    ${mediaQuery.from.S`
      margin-bottom: 32px;
      transform: scale(1.5);
    `}

    ${mediaQuery.from.M`
      transform: scale(2);
    `}
  `,
  Title: styled.h1`
    color: ${colors.blue};
    text-align: center;
    margin-bottom: ${sizes.space * 5}px;
  `,
};

const Register = ({ questionnaire, submitData, addFlashNotifications }) => {
  const { translate } = useContext(I18nContext);
  const router = useHistory();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().required(translate('app.form.errors.required')),
      })
    ),
  });

  const onSubmit = async ({ email }) => {
    // this is because iOS defices can prefill with an extra space and/or capital
    const formattedEmail = email.toLowerCase().replace(' ', '');
    try {
      const response = await submitData(formattedEmail, questionnaire);
      if (!response.error) {
        router.push(paths.questionnaireSuccess);
      } else {
        addFlashNotifications([
          {
            title: translate('app.questionnaire.register.error.title'),
            message: translate('app.questionnaire.register.error.message'),
            type: 'error',
          },
        ]);
      }
    } catch (error) {
      console.error(error);
      addFlashNotifications([
        {
          title: translate('app.questionnaire.register.error.title'),
          message: translate('app.questionnaire.register.error.message'),
          type: 'error',
        },
      ]);
    }
  };

  return (
    <Page noFooter>
      <Seo title="Register" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <section>
          <div>
            <Container size="medium">
              <Styled.Intro>
                <Styled.Icon icon="validated" width={40} />
                <Styled.Title>{translate('app.questionnaire.register.title')}</Styled.Title>
              </Styled.Intro>
            </Container>
            <Container size="extraSmall">
              <TextField
                {...register('email')}
                name="email"
                value={watch('email')}
                label={translate('app.form.email.label')}
                placeholder={translate('app.form.email.placeholder')}
                clearValue={() => setValue('email', '')}
                setFocus
                error={errors.email?.message}
              />
            </Container>
          </div>
        </section>
        <Stepper
          nextLabel={translate('app.form.finish')}
          onBackClick={() => router.push(paths.questionnaireOverview)}
        />
      </form>
    </Page>
  );
};

Register.propTypes = {
  questionnaire: PropTypes.shape({
    activeStepIndex: PropTypes.number.isRequired,
    filters: PropTypes.shape({}),
  }).isRequired,
  submitData: PropTypes.func.isRequired,
  addFlashNotifications: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    questionnaire: state.questionnaire,
  }),
  (dispatch) => ({
    submitData: (email, data) => dispatch(questionnaireActions.submitData(email, data)),
    addFlashNotifications: (notificationId) =>
      dispatch(flashNotificationsActions.addFlashNotifications(notificationId)),
  })
)(Register);
