import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// Redux
import * as listingActions from '../redux/actions/listing';

// Providers
import { I18nContext } from '../providers/I18nContextProvider';

// Utils
import { formatMoney } from '../utils/formatNumber';

// Styling
import sizes from '../styles/sizes';
import textStyles from '../styles/textStyles';
import mediaQuery from '../styles/breakpoints';

// Components
import Seo from '../components/Seo';
import Page from '../components/layouts/Page';
import Container from '../components/layouts/Container';
import Logo from '../components/assets/Logo';
import Shape from '../components/assets/Shape';
import PhotoGallery from '../components/PhotoGallery';
import ListingFeatureCard from '../components/ListingFeatureCard';
import AccordionSingle from '../components/AccordionSingle';
import ListingDetailSection from '../components/ListingDetailSection';
import GoogleMaps from '../components/GoogleMaps';
import RealtorCard from '../components/RealtorCard';
import ShareCard from '../components/ShareCard';
import Loader from '../components/elements/Loader';

const StyledShapeWrapper = styled.div`
  position: relative;
`;
const StyledShape = styled(Shape)`
  overflow: hidden;
  width: 120%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(15%, -50%);
`;
const StyledContainer = styled(Container)`
  display: flex;
  position: relative;
`;
const StyledLogoLink = styled(Link)`
  z-index: 1;
  display: block;
  margin: 0 auto;
`;
const StyledLogo = styled(Logo)`
  width: 120px;
  margin-top: ${sizes.space * 2}px;

  ${mediaQuery.from.S`
    width: 180px;
    margin-top: ${sizes.space * 4}px;
  `}

  ${mediaQuery.from.M`
    width: 240px;
  `}
`;
const StyledPhotoGallery = styled(PhotoGallery)`
  margin-top: -${sizes.space * 2}px;
  margin-bottom: ${sizes.space * 3}px;

  ${mediaQuery.from.S`
    margin-top: -${sizes.space * 3}px;
  `}

  ${mediaQuery.from.M`
    margin-bottom: ${sizes.space * 4}px;
  `}
`;
const StyledSummary = styled.div`
  width: 100%;
  margin-bottom: ${sizes.space * 8}px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > p {
    margin-bottom: 0;
  }
`;
const StyledPrice = styled.h2`
  ${textStyles.titleNormal};
  margin-top: ${sizes.space * 5}px;
  margin-bottom: ${sizes.space / 2}px;

  ${mediaQuery.from.M`
    margin-bottom: ${sizes.space}px;
  `}
`;
const StyledListingFeatureCard = styled(ListingFeatureCard)`
  z-index: 1;
  position: relative;
  min-height: 64px;
  margin-bottom: ${sizes.space * 8}px;

  ${mediaQuery.from.S`
    min-height: 96px;
    margin-bottom: ${sizes.space * 13}px;
  `}
`;
const StyledAccordionSingle = styled(AccordionSingle)`
  margin-bottom: ${sizes.space * 8}px;

  ${mediaQuery.from.S`
    margin-bottom: ${sizes.space * 13}px;
  `}
`;
const StyledMapContainer = styled(Container)`
  margin-bottom: ${sizes.space * 13}px;
`;
const StyledMapTitle = styled.h3`
  ${textStyles.titleLoud};
  margin-bottom: ${sizes.space * 2}px;
  text-align: center;
`;
const StyledFooterCards = styled(Container)`
  > div:first-of-type {
    margin-bottom: ${sizes.space * 4}px;
  }

  ${mediaQuery.from.S`
    display: flex;

    > div {
      width: 50%;
      margin-right: ${sizes.space * 5}px;

      :first-of-type {
        margin-bottom: 0;
      }
      :last-of-type {
        margin-right: 0;
      }
    }
  `}
`;

const ListingDetail = ({ listing, fetchListing }) => {
  const { translate } = useContext(I18nContext);
  const listingId = useParams().id;
  const history = useHistory();

  useEffect(() => {
    fetchListing(listingId);
  }, [listingId]);

  useEffect(() => {
    if (listing?.status === 'failed') {
      history.push('/404');
    }
  }, [listing]);

  const listingData = useMemo(() => {
    if (listing?.data?.listings[0]) {
      return listing?.data?.listings[0];
    }
    return {};
  }, [listing?.data?.listings]);

  const title = useMemo(() => {
    return listing?.data
      ? `${listing?.data?.address} ${listing?.data?.postal}, ${listing?.data?.city}`
      : 'Listing - Rplace';
  }, [listing?.data]);

  const formattedDescription = useMemo(() => {
    if (listingData.descriptions && listingData.descriptions.length) {
      return `${listingData.descriptions[0]?.substring(0, 152)}..`;
    }
    return '';
  }, [listingData.descriptions]);

  const price = useMemo(() => {
    return `€ ${formatMoney(listingData.price)} ${
      listingData.type === 'rental' ? translate('app.listing.rental.month') : ''
    }`;
  }, [listing?.data]);

  const meterPrice = useMemo(() => {
    return `€ ${formatMoney(listingData.price / listing?.data?.size)} per m²`;
  }, [listingData.price, listing?.data?.size]);

  const priceDetailItems = useMemo(() => {
    const items = [{ label: translate('app.listing.details.price.askingPrice'), value: price }];
    if (listingData.type === 'purchase') {
      items.push({
        label: `${translate('app.listing.details.price.askingPrice')} per m2`,
        value: meterPrice,
      });
    }
    return items;
  }, [listingData.type]);

  const layoutDetailItems = useMemo(() => {
    const items = [
      {
        label: translate('app.listing.details.layout.rooms'),
        value: listing?.data?.rooms,
      },
      {
        label: translate('app.listing.details.layout.bedrooms'),
        value: listing?.data?.bedrooms,
      },
    ];
    if (listing?.data?.bathrooms !== 0) {
      items.push({
        label: translate('app.listing.details.layout.bathrooms'),
        value: listing?.data?.bathrooms,
      });
    }
    return items;
  }, [listing?.data?.bathrooms]);

  if (!listing || !Object.keys(listing).length || listing?.status !== 'success') {
    return <Loader />;
  }

  return (
    <Page noHeader>
      <Seo title={title} description={formattedDescription} image={listingData.images[0]} />
      <StyledShapeWrapper>
        <StyledShape />
      </StyledShapeWrapper>
      <StyledContainer>
        <StyledLogoLink to="/">
          <StyledLogo inverted beta />
        </StyledLogoLink>
      </StyledContainer>
      <StyledPhotoGallery images={listingData.images} alt={`${title}-image`} />
      <StyledContainer>
        <StyledSummary>
          <h1>{listing?.data?.address}</h1>
          <p>
            {listing?.data?.postal} {listing?.data?.city}
          </p>
          <StyledPrice>{price}</StyledPrice>
          {listingData.type === 'purchase' && (
            <p>
              {translate('app.listing.purchase.meterPriceLabel')} {meterPrice}
            </p>
          )}
        </StyledSummary>
      </StyledContainer>
      <Container>
        <StyledListingFeatureCard
          size={listing?.data?.size}
          rooms={listing?.data?.rooms}
          bedrooms={listing?.data?.bedrooms}
          location={{ lat: listing?.data?.latitude, lng: listing?.data?.longitude }}
        />
      </Container>
      <Container size="small">
        <StyledAccordionSingle title={translate('app.listing.descriptionTitle')}>
          {/* eslint-disable react/no-array-index-key */}
          {listingData.descriptions &&
            listingData.descriptions.map((description, i) => (
              <p key={`description-paragraph-${i}`}>{description}</p>
            ))}
        </StyledAccordionSingle>
        <StyledAccordionSingle title={translate('app.listing.details.title')} initialHeight={400}>
          <>
            <ListingDetailSection
              title={translate('app.listing.details.price.title')}
              icon="money"
              items={priceDetailItems}
            />
            <ListingDetailSection
              title={translate('app.listing.details.offer.title')}
              icon="houseApartment"
              items={[{ label: 'Status', value: listingData.status }]}
            />
            {listing?.data?.construction_year !== 0 && (
              <ListingDetailSection
                title={translate('app.listing.details.construction.title')}
                icon="bricks"
                items={[
                  {
                    label: translate('app.listing.details.construction.year'),
                    value: listing?.data?.construction_year,
                  },
                ]}
              />
            )}
            <ListingDetailSection
              title={translate('app.listing.details.surface.title')}
              icon="surface"
              items={[
                {
                  label: translate('app.listing.details.surface.size'),
                  value: `${listing?.data?.size}m2`,
                },
              ]}
            />
            <ListingDetailSection
              title={translate('app.listing.details.layout.title')}
              icon="parcel"
              items={layoutDetailItems}
            />
            {listing?.data?.energy_rating && (
              <ListingDetailSection
                title={translate('app.listing.details.energy.title')}
                icon="label"
                items={[
                  {
                    label: translate('app.listing.details.energy.label'),
                    value: listing?.data?.energy_rating,
                  },
                ]}
              />
            )}
          </>
        </StyledAccordionSingle>
      </Container>
      {listing?.data?.latitude !== 0 && listing?.data?.longitude !== 0 && (
        <StyledMapContainer>
          <StyledMapTitle>{translate('app.listing.location')}</StyledMapTitle>
          <GoogleMaps location={{ lat: listing?.data?.latitude, lng: listing?.data?.longitude }} />
        </StyledMapContainer>
      )}
      <StyledFooterCards size="medium">
        <RealtorCard
          name={listingData.agency.name}
          phone={listingData.agency.phone}
          website={listingData.agency.website}
        />
        <ShareCard
          title={translate('app.listing.aboutText.title')}
          button={{
            readMore: translate('app.listing.aboutText.readMore'),
            readLess: translate('app.listing.aboutText.readLess'),
          }}
          url={window.location.href}
        />
      </StyledFooterCards>
    </Page>
  );
};

ListingDetail.propTypes = {
  fetchListing: PropTypes.func.isRequired,
  listing: PropTypes.shape({
    status: PropTypes.oneOf(['success', 'failed']),
    data: PropTypes.shape({
      id: PropTypes.string,
      identifier: PropTypes.string,
      address: PropTypes.string,
      city: PropTypes.string,
      district: PropTypes.string,
      postal: PropTypes.string,
      application: PropTypes.string,
      rooms: PropTypes.number,
      bathrooms: PropTypes.number,
      bedrooms: PropTypes.number,
      construction_year: PropTypes.number,
      size: PropTypes.number,
      energy_rating: PropTypes.string,
      longitude: PropTypes.number,
      latitude: PropTypes.number,
      location_hash: PropTypes.string,
      listings: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          agency: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            phone: PropTypes.string,
            website: PropTypes.string,
            image: PropTypes.string,
          }),
          available_at: PropTypes.number,
          offered_at: PropTypes.number,
          price: PropTypes.number,
          descriptions: PropTypes.arrayOf(PropTypes.string),
          type: PropTypes.string,
          status: PropTypes.string,
          images: PropTypes.arrayOf(PropTypes.string),
          events: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              type: PropTypes.string,
              description: PropTypes.string,
            })
          ),
          meta: PropTypes.shape({
            service: PropTypes.string,
            source: PropTypes.string,
          }),
          updated_at: PropTypes.number,
        })
      ),
    }),
  }),
};

ListingDetail.defaultProps = {
  listing: {},
};

export default connect(
  (state) => ({
    listing: state.listing,
  }),
  (dispatch) => ({
    fetchListing: (id) => dispatch(listingActions.fetchListing(id)),
  })
)(ListingDetail);
