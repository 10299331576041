import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Styling
import colors from '../../styles/colors';

const StyledPath = styled.path`
  transition: fill 0.2s ease-in-out;
`;

const Logo = ({ inverted, beta, className }) => (
  <svg viewBox="0 0 174 120" className={className}>
    <g clipPath="url(#clip)">
      <StyledPath
        d="M1.073 55.67c3.38-6.658 15.464-14.88 26.913-24.386 11.727-9.304 22.739-19.893 30.61-26.67 8.269-6.378 13.397-6.699 20.99 2.366 7.235 8.783 15.583 27.192 20.433 51.296 4.492 23.944 3.26 46.123-3.498 55.548-6.758 10.067-16.736 6.939-32.916-3.489C47.743 99.867 28.94 87.033 16.259 78.129 3.339 68.824-2.624 62.73 1.073 55.67z"
        fill={inverted ? colors.white : colors.primary}
      />
      <StyledPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.376 46.765v25.307h-5.605V66.98c-1.868 3.489-5.048 5.655-9.143 5.655-6.877 0-12.085-5.695-12.085-13.236 0-7.54 5.208-13.235 12.085-13.235 4.095 0 7.275 2.166 9.143 5.655v-5.053h5.605zm-5.605 12.633c0-3.409-3.299-6.016-7.712-6.016-4.412 0-7.672 2.567-7.672 6.016 0 3.41 3.26 6.016 7.672 6.016 4.413 0 7.712-2.607 7.712-6.016zm9.819 0c0-7.54 5.804-13.235 13.516-13.235 6.798 0 12.125 4.452 13.238 10.749h-6.082c-1.114-2.126-3.896-3.53-7.116-3.53-4.413 0-7.712 2.567-7.712 6.016 0 3.41 3.299 6.016 7.712 6.016 3.259 0 6.002-1.403 7.116-3.529h6.082c-1.074 6.297-6.44 10.749-13.238 10.749-7.712 0-13.516-5.695-13.516-13.236zm35.142 2.126h21.109c.079-.602.159-1.324.119-1.965 0-7.74-5.804-13.396-13.516-13.396s-13.516 5.695-13.516 13.235c0 7.54 5.804 13.236 13.556 13.236 6.161 0 11.17-3.49 13.039-8.583h-6.202c-1.113 1.083-3.737 1.925-6.678 1.925-3.976 0-6.878-1.725-7.911-4.452zm15.503-4.291h-15.424c.994-2.688 3.976-4.452 7.673-4.412 3.736 0 6.758 1.684 7.751 4.412z"
        fill={inverted ? colors.white : colors.primary}
      />
      <StyledPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.564 36.337h5.606v35.695h-5.606V36.337zM60.147 72.634c6.917 0 12.085-5.695 12.085-13.236 0-7.54-5.168-13.235-12.085-13.275-4.095 0-7.315 2.206-9.143 5.695v-5.093h-5.606v34.732h5.606V66.94c1.828 3.449 5.048 5.695 9.143 5.695zm-1.431-19.252c4.412 0 7.712 2.607 7.712 6.017 0 3.409-3.3 6.016-7.712 6.016-4.413 0-7.712-2.607-7.712-6.017 0-3.449 3.3-6.016 7.712-6.016zM41.66 55.307v-8.944c-4.014 0-7.195 2.527-9.143 6.698v-6.297h-5.605v25.308h5.605V61.443c0-3.97 3.18-6.136 9.143-6.136zm50.726 4.051c0 3.45 3.26 6.016 7.672 6.016.364 0 .695-.033 1.054-.07l.099-.01c.345 2.203.579 4.37.809 6.498l.026.24a10.546 10.546 0 01-3.42.562c-6.876 0-12.084-5.696-12.084-13.236 0-7.018 4.532-12.433 10.694-13.155a159.402 159.402 0 011.788 7.22c-3.895.36-6.638 2.807-6.638 5.935z"
        fill={inverted ? colors.primary : colors.white}
      />
      {beta && (
        <StyledPath
          d="M115.746 85.33c1.589 0 2.907.559 3.955 1.675 1.055 1.11 1.582 2.496 1.582 4.16 0 1.657-.527 3.044-1.582 4.16-1.048 1.117-2.366 1.675-3.955 1.675-1.743 0-3.077-.736-3.999-2.208v1.941H109V81h2.747v6.539c.922-1.472 2.256-2.208 3.999-2.208zm-3.044 7.755c.638.512 1.436.768 2.396.768.959 0 1.757-.256 2.395-.768.637-.512.955-1.152.955-1.92 0-.775-.318-1.415-.955-1.92-.638-.512-1.436-.768-2.395-.768-.96 0-1.758.256-2.396.768-.637.505-.955 1.145-.955 1.92 0 .768.318 1.408.955 1.92zM134.961 91.23c0 .32-.022.61-.066.874h-9.503c.212.612.63 1.099 1.252 1.461.623.363 1.363.544 2.22.544 1.333 0 2.307-.298 2.922-.896h3.01c-.446 1.138-1.212 2.055-2.296 2.752-1.077.69-2.311 1.035-3.702 1.035-1.773 0-3.252-.558-4.439-1.675-1.186-1.116-1.78-2.503-1.78-4.16 0-1.664.59-3.05 1.769-4.16 1.179-1.116 2.652-1.674 4.417-1.674 1.772 0 3.248.558 4.427 1.674 1.179 1.11 1.769 2.518 1.769 4.224zm-4.043-2.475c-.623-.363-1.34-.544-2.153-.544-.806 0-1.513.185-2.121.554-.608.363-1.025.843-1.252 1.44h6.779c-.213-.611-.63-1.095-1.253-1.45zM143.75 88.67h-3.142v3.829c0 .753.425 1.13 1.275 1.13h1.648v3.104h-1.934c-2.505 0-3.757-1.159-3.757-3.477v-4.587h-2.231v-3.072h2.231v-2.581l2.768-.704v3.285h3.142v3.072zM154.253 85.597H157v11.136h-2.747v-1.92c-.959 1.458-2.296 2.187-4.01 2.187-1.574 0-2.893-.558-3.955-1.675-1.055-1.116-1.582-2.503-1.582-4.16 0-1.664.527-3.05 1.582-4.16 1.062-1.116 2.381-1.674 3.955-1.674 1.707 0 3.044.729 4.01 2.186v-1.92zm-5.735 7.488c.63.512 1.425.768 2.384.768.96 0 1.758-.256 2.396-.768.637-.512.955-1.152.955-1.92 0-.775-.318-1.415-.955-1.92-.638-.512-1.436-.768-2.396-.768-.959 0-1.754.256-2.384.768-.629.505-.944 1.145-.944 1.92 0 .768.315 1.408.944 1.92z"
          fill={inverted ? colors.white : colors.primary}
        />
      )}
    </g>
    <defs>
      <clipPath id="clip">
        <StyledPath fill={inverted ? colors.primary : colors.white} d="M0 0h174v120H0z" />
      </clipPath>
    </defs>
  </svg>
);

Logo.propTypes = {
  inverted: PropTypes.bool,
  beta: PropTypes.bool,
  className: PropTypes.string.isRequired,
};

Logo.defaultProps = {
  inverted: false,
  beta: false,
};

export default Logo;
