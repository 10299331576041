import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import Error from '../elements/Error';

// Styles
import sizes from '../../styles/sizes';
import colors from '../../styles/colors';

const Styled = {
  Component: styled.label`
    cursor: pointer;
    display: block;
    margin-bottom: ${sizes.space * 2}px;
  `,
  Label: styled.label`
    display: block;
    font-size: 12px;
    line-height: normal;
    color: ${colors.black};
    margin-bottom: ${sizes.space}px;
    user-select: none;
  `,
  Holder: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    ${(props) => props.disabled && 'opacity: 0.5;'}
    ${(props) => props.error && `color: ${colors.red};`}
  `,
  Input: styled.div`
    position: fixed;
    top: -500px;
    left: -500px;
  `,
  Icon: styled.div`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 5px;
    border: 2px solid ${(props) => (props.checked ? colors.primary : colors.grey)};
    margin-right: ${sizes.space * 2}px;
    transition: border 0.2s ease;
  `,
  Bullet: styled.div`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${(props) => (props.checked ? colors.primary : colors.white)};
    transition: background-color 0.2s ease;
  `,
  Error: styled.div`
    height: 16px;
    font-size: 10px;
    color: ${colors.red};
    line-height: 16px;
  `,
};

const Radio = ({ register, name, value, checked, label, children, disabled, error, onChange }) => (
  <Styled.Component>
    {!!label && <Styled.Label htmlFor={value}>{label}</Styled.Label>}
    <Styled.Holder error={error} disabled={disabled}>
      <Styled.Icon checked={checked}>
        <Styled.Bullet checked={checked} />
      </Styled.Icon>
      <Styled.Input error={error}>
        <input
          {...register(name)}
          type="radio"
          id={value}
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={() => {
            if (disabled) return;
            onChange(value);
          }}
        />
      </Styled.Input>
      {children}
    </Styled.Holder>
    {!!error && <Error label={error} />}
  </Styled.Component>
);

Radio.propTypes = {
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checked: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

Radio.defaultProps = {
  defaultValue: '',
  value: 'on',
  label: null,
  checked: false,
  error: null,
  disabled: false,
};

export default Radio;
