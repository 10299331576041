export const formatNumber = (value, thousandsChar = '.') => {
  if (!value) {
    return '';
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandsChar);
};

/**
 * Format money
 *
 * @param value
 * @param thousandsChar
 * @param decimalChar
 * @param decimal
 * @param thousands
 * @returns {string}
 */
export const formatMoney = (
  value,
  decimal = 0,
  decimalChar = ',',
  thousands = 3,
  thousandsChar = '.'
) => {
  const re = `\\d(?=(\\d{${thousands}})+${decimal > 0 ? '\\D' : '$'})`;

  let val = 0.0;
  if (value) {
    val = value;
  }
  const num = parseFloat(val)
    .toFixed(Math.max(0, decimal))
    .replace(`${decimalChar}00`, `${decimalChar}-`);

  return (decimalChar ? num.replace('.', decimalChar) : num).replace(
    new RegExp(re, 'g'),
    `$&${thousandsChar}`
  );
};
