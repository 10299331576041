import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Providers
import { I18nContext } from '../providers/I18nContextProvider';

// Components
import Seo from '../components/Seo';
import Page from '../components/layouts/Page';
import Loader from '../components/elements/Loader';
import Intro from '../components/Intro';
import Cta from '../components/Cta';
import Steps from '../components/Steps';
import Usps from '../components/Usps';
import Stats from '../components/Stats';

// Redux
import * as contentActions from '../redux/actions/content';

const Styled = {
  LoaderHolder: styled.section`
    min-height: 75vh;
  `,
};

const Home = ({ fetchStats, fetchContent, content }) => {
  const { langCode, translate } = useContext(I18nContext);

  useEffect(() => {
    fetchStats();
    fetchContent(langCode, 'homepage');
  }, []);

  if (!content.home) {
    return (
      <Styled.LoaderHolder>
        <Loader />
      </Styled.LoaderHolder>
    );
  }

  return (
    <Page>
      <Seo description={content.home.meta.description} />
      <section>
        <Intro
          title={content.home.intro.title}
          uspList={content.home.intro.uspList}
          image={content.home.intro.image}
          ctaLabel={translate('app.home.intro.ctaLabel')}
        />
      </section>
      <section>
        <Cta
          title={translate('app.home.cta.title')}
          buyLabel={translate('app.home.cta.buyLabel')}
          rentLabel={translate('app.home.cta.rentLabel')}
        />
      </section>
      <section>
        <Steps
          title={content.home.steps.title}
          steps={content.home.steps.steps}
          buttonLabel={content.home.steps.buttonLabel}
        />
      </section>
      <section>
        <Usps points={content.home.usps.usps} buttonLabel={content.home.usps.buttonLabel} />
      </section>
      <section>
        <Stats
          title={content.home.stats.title}
          image={content.home.stats.image}
          stats={content.stats}
          subtitle={content.home.stats.subtitle}
        />
      </section>
      <Cta
        title={translate('app.home.cta.title')}
        buyLabel={translate('app.home.cta.buyLabel')}
        rentLabel={translate('app.home.cta.rentLabel')}
      />
    </Page>
  );
};

Home.propTypes = {
  fetchStats: PropTypes.func.isRequired,
  fetchContent: PropTypes.func.isRequired,
  content: PropTypes.shape({
    home: PropTypes.shape({
      meta: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
      intro: PropTypes.shape({
        title: PropTypes.string,
        uspList: PropTypes.arrayOf(PropTypes.string),
        image: PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string,
          dimensions: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
          }),
        }),
        ctaLabel: PropTypes.string,
      }),
      cta: PropTypes.shape({
        title: PropTypes.string,
        buyLabel: PropTypes.string,
        rentLabel: PropTypes.string,
      }),
      steps: PropTypes.shape({
        title: PropTypes.string,
        steps: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            text: PropTypes.string,
          })
        ),
        buttonLabel: PropTypes.string,
      }),
      usps: PropTypes.shape({
        usps: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            text: PropTypes.string,
            image: PropTypes.shape({
              title: PropTypes.string,
              text: PropTypes.string,
              image: PropTypes.shape({
                url: PropTypes.string,
                alt: PropTypes.string,
                dimensions: PropTypes.shape({
                  width: PropTypes.number,
                  height: PropTypes.number,
                }),
              }),
            }),
          })
        ),
        buttonLabel: PropTypes.string,
      }),
      stats: PropTypes.shape({
        title: PropTypes.string,
        image: PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string,
          dimensions: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
          }),
        }),
        subtitle: PropTypes.string,
      }),
    }),
    stats: PropTypes.shape({
      total_purchase: PropTypes.number,
      total_rental: PropTypes.number,
    }),
  }).isRequired,
};

export default connect(
  (state) => ({
    content: state.content,
  }),
  (dispatch) => ({
    fetchStats: () => dispatch(contentActions.fetchStats()),
    fetchContent: (locale, page) => dispatch(contentActions.fetchContent(locale, page)),
  })
)(Home);
