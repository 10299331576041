import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Constants
import { buyFlow } from '../../constants/questionnaire';

// Components
import Seo from '../../components/Seo';
import Page from '../../components/layouts/Page';
import QuestionnaireForm from '../../components/QuestionnaireForm';
import Progressbar from '../../components/elements/Progressbar';

const Styled = {
  ProgressbarHolder: styled.div`
    position: fixed;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 100%;
  `,
};

const Buy = ({ activeStepIndex }) => {
  let percentage = 0;
  if (activeStepIndex) {
    percentage = Math.round((activeStepIndex / buyFlow.steps.length) * 100);
  }

  return (
    <Page noFooter>
      <Seo title="Buy" />
      <section>
        <QuestionnaireForm flow={buyFlow} type="purchase" />
      </section>
      <Styled.ProgressbarHolder>
        <Progressbar percentage={percentage} />
      </Styled.ProgressbarHolder>
    </Page>
  );
};

Buy.propTypes = {
  activeStepIndex: PropTypes.number.isRequired,
};

export default connect((state) => ({
  activeStepIndex: state.questionnaire.activeStepIndex,
}))(Buy);
