import React, { useContext, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

// Constants
import paths from '../constants/paths';

// Providers
import { I18nContext } from '../providers/I18nContextProvider';

// Styles
import colors from '../styles/colors';
import sizes from '../styles/sizes';
import textStyles from '../styles/textStyles';
import mediaQuery, { sizes as breakpoints } from '../styles/breakpoints';

// Components
import Container from './layouts/Container';
import LanguageSwitch from './elements/LanguageSwitch';
import Burger from './elements/Burger';
import Logo from './assets/Logo';
import ShapeBottom from './assets/ShapeBottom';

const Styled = {
  Container: styled(Container)`
    position: absolute;
    z-index: 9;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  `,
  Header: styled.header`
    z-index: 1;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: ${sizes.space * 2}px 0;
  `,
  Logo: styled(Logo)`
    width: 100px;
    max-width: 174px;
    cursor: pointer;

    ${mediaQuery.from.S`
      width: 174px;
    `}
  `,
  Menu: styled.nav`
    position: relative;
    z-index: 1;

    ul {
      list-style: none;
      display: none;
      margin: 0;

      ${mediaQuery.from.M`
        display: flex;
        align-items: center;
      `}

      li {
        margin: 0;
        margin-right: ${sizes.space * 4}px;

        :last-child {
          margin-right: 0;
        }
      }
    }
  `,
  Link: styled(NavLink)`
    ${textStyles.titleSubtle};
    color: ${colors.black};
    text-decoration: none;
    display: block;
    line-height: 1.6;
    border-bottom: 2px solid transparent;
    transition: border-color 200ms ease-out;

    &:hover {
      border-color: ${colors.black};
    }

    &.active {
      border-bottom: 2px solid ${colors.black};
    }
  `,
  Burger: styled.div`
    position: relative;
    z-index: 2;
    margin-left: ${sizes.space * 3}px;

    ${mediaQuery.from.M`
      display: none;
    `}
  `,
  MobileMenu: styled.nav`
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 110px;
    transform: ${({ menuOpen }) => (menuOpen ? 'translateY(0)' : 'translateY(-100%)')};
    opacity: ${({ menuOpen }) => (menuOpen ? '1' : '0')};
    transition: transform 0.4s ease-in-out, opacity 0.2s ease-in-out;
    background-color: ${colors.primary};

    ${mediaQuery.from.S`
      padding-top: 160px;
    `}

    ${mediaQuery.from.M`
      display: none;
    `}

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      list-style: none;
      margin: 0;

      li {
        margin: 0;
        margin-bottom: ${sizes.space * 4}px;

        :last-child {
          margin-bottom: 0;
        }
      }
    }

    ${mediaQuery.from.M`
      display: none;
    `};
  `,
  ShapeBottom: styled(ShapeBottom)`
    position: absolute;
    width: 100%;
    transition: opacity 0.2s ease-in-out;
    opacity: ${({ menuOpen }) => (menuOpen ? '1' : '0')};
    pointer-events: ${({ menuOpen }) => (menuOpen ? 'auto' : 'none')};
  `,
  Overlay: styled.div`
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.32);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
    opacity: ${({ menuOpen }) => (menuOpen ? '1' : '0')};
  `,
};

const Header = () => {
  const { translate } = useContext(I18nContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const mobileMenuRef = useRef(null);

  useEffect(() => {
    if (menuOpen) {
      disableBodyScroll(mobileMenuRef?.current);
    } else {
      enableBodyScroll(mobileMenuRef?.current);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [menuOpen]);

  const handleResize = () => {
    if (window.innerWidth > breakpoints.M) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Styled.Container menuOpen={menuOpen}>
        <Styled.Header>
          <Link to={paths.home}>
            <Styled.Logo inverted={menuOpen} beta />
          </Link>
          <Styled.Menu>
            <ul>
              <li>
                <Styled.Link to={paths.home} exact activeClassName="active">
                  {translate('app.header.menu.home')}
                </Styled.Link>
              </li>
              <li>
                <Styled.Link to={paths.faq} activeClassName="active">
                  {translate('app.header.menu.faq')}
                </Styled.Link>
              </li>
              <li>
                <Styled.Link to={paths.contact} activeClassName="active">
                  {translate('app.header.menu.contact')}
                </Styled.Link>
              </li>
              <li>
                <LanguageSwitch />
              </li>
            </ul>
          </Styled.Menu>
          <Styled.Burger open={menuOpen}>
            <Burger open={menuOpen} onClick={() => setMenuOpen(!menuOpen)} />
          </Styled.Burger>
        </Styled.Header>
      </Styled.Container>
      <Styled.MobileMenu menuOpen={menuOpen} ref={mobileMenuRef}>
        <ul>
          <li>
            <Styled.Link to={paths.home} exact activeClassName="active">
              {translate('app.header.menu.home')}
            </Styled.Link>
          </li>
          <li>
            <Styled.Link to={paths.faq} activeClassName="active">
              {translate('app.header.menu.faq')}
            </Styled.Link>
          </li>
          <li>
            <Styled.Link to={paths.contact} activeClassName="active">
              {translate('app.header.menu.contact')}
            </Styled.Link>
          </li>
          <li>
            <LanguageSwitch />
          </li>
        </ul>
        <Styled.ShapeBottom menuOpen={menuOpen} />
      </Styled.MobileMenu>
      <Styled.Overlay menuOpen={menuOpen} />
    </>
  );
};

export default Header;
