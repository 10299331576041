import React, { useContext } from 'react';
import styled from 'styled-components';

// Providers
import { I18nContext } from '../../providers/I18nContextProvider';

// Components
import Seo from '../../components/Seo';
import Page from '../../components/layouts/Page';
import Container from '../../components/layouts/Container';
import Icon from '../../components/icons/Icon';

// Styling
import mediaQuery from '../../styles/breakpoints';
import colors from '../../styles/colors';
import sizes from '../../styles/sizes';

const Styled = {
  PageInner: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Icon: styled(Icon)`
    margin-bottom: 16px;

    ${mediaQuery.from.S`
      margin-bottom: 32px;
      transform: scale(1.5);
    `}

    ${mediaQuery.from.M`
      transform: scale(2);
    `}
  `,
  Title: styled.h1`
    color: ${colors.blue};
    text-align: center;
    margin-bottom: ${sizes.space * 5}px;
  `,
  Text: styled.p`
    text-align: center;
    margin-bottom: ${sizes.space * 5}px;
  `,
};

const Success = () => {
  const { translate } = useContext(I18nContext);
  return (
    <Page noFooter>
      <Seo title="Success" />
      <section>
        <div>
          <Container size="medium">
            <Styled.PageInner>
              <Styled.Icon icon="newMail" width={40} />
              <Styled.Title>{translate('app.questionnaire.success.title')}</Styled.Title>
            </Styled.PageInner>
          </Container>
          <Container size="extraSmall">
            <Styled.Text>{translate('app.questionnaire.success.text')}</Styled.Text>
          </Container>
        </div>
      </section>
    </Page>
  );
};

export default Success;
