import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';

// Styling
import textStyles from '../../styles/textStyles';
import colors from '../../styles/colors';
import sizes from '../../styles/sizes';

const Container = styled.div`
  h2 {
    ${textStyles.titleNormal};
    margin-top: ${sizes.space * 4}px;
    margin-bottom: ${sizes.space * 2}px;
  }

  a {
    color: ${colors.primary};
  }
`;

const Richtext = ({ copy }) => <Container>{RichText.render(copy)}</Container>;

Richtext.propTypes = {
  copy: propTypes.string.isRequired,
};

export default Richtext;
