import React, { useCallback, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

// Styling
import colors from '../styles/colors';
import mediaQuery from '../styles/breakpoints';
import sizes from '../styles/sizes';
import textStyles from '../styles/textStyles';

// Components
import Container from './layouts/Container';
import Icon from './icons/Icon';

const sharedButtonStyling = css`
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  transition: transform 0.08s ease-in-out;

  ${mediaQuery.from.S`
    display: flex;
  `}
`;

const sharedImageStyling = css`
  cursor: pointer;
  position: relative;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  overflow: hidden;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.primary};
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  :hover:before {
    opacity: 0.4;
  }
`;

const sharedCounterStyling = css`
  z-index: 1;
  position: absolute;
  bottom: ${sizes.space * 2}px;
  left: 50%;
  color: ${colors.black};
  transform: translateX(-50%);
`;

const StyledContainer = styled(Container)`
  ${mediaQuery.to.S`
    padding: 0;
  `}
`;

const StyledGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledFeaturedPhoto = styled.div`
  ${sharedImageStyling};
  width: 100%;
  padding-top: 60%;
  background-color: ${colors.grey};

  ${mediaQuery.from.S`
    width: calc(50% - 8px);
    margin-right: 8px;
    padding-top: 37.5%;
    border-bottom-left-radius: 18px;
    border-top-left-radius: 18px;
  `}
`;

const StyledPhoto = styled.div`
  ${sharedImageStyling};
  width: calc(50% - (${sizes.space}px / 2));
  max-height: 50%;
  padding-top: 20%;
  background-color: ${colors.grey};

  :nth-child(1) {
    margin-bottom: ${sizes.space}px;
  }

  :nth-child(2) {
    margin-bottom: ${sizes.space}px;
    margin-left: ${sizes.space}px;
    border-top-right-radius: ${sizes.borderRadiusImage}px;
  }

  :nth-child(4) {
    margin-left: ${sizes.space}px;
    border-bottom-right-radius: ${sizes.borderRadiusImage}px;
  }
`;

const StyledPhotosWrapper = styled.div`
  display: none;

  ${mediaQuery.from.S`
    display: flex;
    flex-wrap: wrap;
    width: 50%;
  `}
`;

const StyledModal = styled.dialog`
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${colors.white};
  border: none;
  display: ${({ open }) => (open ? 'flex' : 'none')};
`;

const StyledImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImage = styled.img`
  max-width: 80vw;
  max-height: 80vh;
`;

const StyledNext = styled.button`
  ${sharedButtonStyling};
  right: 23px;
  transform: rotate(-90deg);

  :hover {
    transform: scale(1.2) rotate(-90deg);
  }
`;

const StyledPrevious = styled.button`
  ${sharedButtonStyling};
  left: 23px;
  transform: rotate(90deg);

  :hover {
    transform: scale(1.2) rotate(90deg);
  }
`;

const StyledCounter = styled.div`
  ${textStyles.bodyLarge};
  ${sharedCounterStyling};
`;

const StyledMobileCounter = styled.div`
  ${textStyles.bodySmall};
  ${sharedCounterStyling};
  padding: ${sizes.space / 2}px;
  color: ${colors.white};
  background-color: ${colors.black};
  border-radius: ${sizes.space / 2}px;

  ${mediaQuery.from.S`
    display: none;
  `}
`;

const StyledClose = styled.button`
  cursor: pointer;
  position: absolute;
  top: ${sizes.space * 2}px;
  right: ${sizes.space * 2}px;
  display: flex;
  padding: 0;
  background: transparent;
  border: 0;
  outline: none;
  transition: transform 0.08s ease-in-out;

  ${mediaQuery.from.S`
    top: 32px;
    right: 32px;
  `}

  :hover {
    transform: scale(1.2);
  }
`;

const PhotoGallery = ({ images, alt, className }) => {
  const handleDragStart = (e) => e.preventDefault();

  const items = images.map((image, i) => (
    <StyledImageWrapper>
      <StyledImage src={image} alt={`${alt}-${i + 1}`} onDragStart={handleDragStart} />
    </StyledImageWrapper>
  ));

  const sliderRef = useRef(null);
  const modalRef = useRef(null);

  const [openModal, setOpenModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [sliderCounter, setSliderCounter] = useState(1);

  const handleOpenCarousel = (index) => {
    setActiveIndex(index);
    setSliderCounter(index + 1);
    setOpenModal(true);
  };

  const handleSliderCounter = (event) => {
    setActiveIndex(event.item);
    setSliderCounter(event.item + 1);
  };

  const handleKeyDown = useCallback((event) => {
    // esc key
    if (event.keyCode === 27) {
      setOpenModal(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, []);

  useEffect(() => {
    if (openModal) {
      disableBodyScroll(modalRef?.current);
    } else {
      enableBodyScroll(modalRef?.current);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [openModal]);

  return (
    <>
      <StyledContainer className={className}>
        <StyledGallery>
          <StyledFeaturedPhoto
            key={images[0]}
            src={images[0]}
            role="img"
            aria-label={`${alt}-1`}
            onClick={() => handleOpenCarousel(0)}
          >
            {!openModal && <StyledMobileCounter>1 / {images.length}</StyledMobileCounter>}
          </StyledFeaturedPhoto>
          <StyledPhotosWrapper>
            {images.map((photo, i) => {
              if (i !== 0 && i < 5) {
                return (
                  <StyledPhoto
                    key={photo}
                    src={photo}
                    role="img"
                    aria-label={`${alt}-${i + 1}`}
                    onClick={() => handleOpenCarousel(i)}
                  />
                );
              }
              return null;
            })}
          </StyledPhotosWrapper>
        </StyledGallery>
      </StyledContainer>

      <StyledModal open={openModal} ref={modalRef}>
        <StyledClose type="button" onClick={() => setOpenModal(false)}>
          <Icon icon="cross" width={32} color={colors.black} />
        </StyledClose>
        <StyledNext type="button" onClick={() => sliderRef?.current?.slideNext()}>
          <Icon icon="chevron" width={40} color={colors.black} />
        </StyledNext>
        <StyledPrevious type="button" onClick={() => sliderRef?.current?.slidePrev()}>
          <Icon icon="chevron" width={40} color={colors.black} />
        </StyledPrevious>
        <AliceCarousel
          mouseTracking
          items={items}
          activeIndex={activeIndex}
          infinite
          animationDuration={250}
          keyboardNavigation
          disableDotsControls
          disableButtonsControls
          autoHeight
          ref={sliderRef}
          onSlideChanged={handleSliderCounter}
        />
        <StyledCounter>
          {sliderCounter} / {images.length}
        </StyledCounter>
      </StyledModal>
    </>
  );
};

PhotoGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default PhotoGallery;
