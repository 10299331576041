import React, { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// Providers
import { I18nContext } from '../providers/I18nContextProvider';

const Seo = ({ title, description, image }) => {
  const formattedTitle = title ? `${title} - Rplace` : title;
  const { langCode } = useContext(I18nContext);
  const [lang, setLang] = useState('nl-nl');

  useEffect(() => {
    setLang(langCode === 'nl' ? 'nl-nl' : 'en-nl');
  }, [langCode]);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
    >
      <title>{formattedTitle}</title>
      <meta property="og:title" content={formattedTitle} />
      <meta name="twitter:title" content={formattedTitle} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />
      <meta property="og:image" content={image} />
      <meta name="ssr:ts" content={new Date().valueOf()} /> {/* ready flag for SSR */}
    </Helmet>
  );
};

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

Seo.defaultProps = {
  title: 'Rplace',
  description: '',
  image: '/images/share.jpg',
};

export default Seo;
