import React from 'react';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';

const Cross = ({ width, color, className }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 13 12"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.914.086L.5 1.5 5 6 .5 10.5l1.414 1.414 4.5-4.5 4.457 4.457 1.414-1.414L7.828 6l4.457-4.457L10.871.13 6.414 4.586l-4.5-4.5z"
      fill={color}
    />
  </svg>
);

Cross.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  color: PropTypes.string,
};

Cross.defaultProps = {
  color: colors.black,
};

export default Cross;
