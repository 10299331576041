import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Styles
import sizes from '../../styles/sizes';
import mediaQuery from '../../styles/breakpoints';

// Components
import Button from './Button';

const StyledComponent = styled.div`
  z-index: 1;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: ${sizes.space * 4}px;
  display: flex;
  justify-content: center;

  ${mediaQuery.from.XXS`
    bottom: ${sizes.space * 6}px;
  `}

  > button:first-child {
    margin-right: ${sizes.space * 2}px;
  }
`;

const FormStepper = ({ nextLabel, onBackClick, onNextClick }) => (
  <StyledComponent>
    <Button version="secondary" rotateIcon onClick={onBackClick} />
    <Button
      type="submit"
      label={nextLabel}
      onClick={() => {
        if (onNextClick) {
          onNextClick();
        }
      }}
    />
  </StyledComponent>
);

FormStepper.propTypes = {
  nextLabel: PropTypes.string.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func,
};

FormStepper.defaultProps = {
  onNextClick: null,
};

export default FormStepper;
