import React from 'react';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';

const Validated = ({ width, className }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 32 32"
    className={className}
  >
    <path
      d="M32 16c0 8.837-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0s16 7.163 16 16z"
      fill={colors.primaryLight}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 16c0 7.732-6.268 14-14 14S2 23.732 2 16 8.268 2 16 2s14 6.268 14 14zm2 0c0 8.837-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0s16 7.163 16 16zM8 16l1.414-1.414 4.489 4.488 7.714-7.714 1.414 1.414-7.714 7.714.002.002-1.415 1.414L8 16z"
      fill={colors.primary}
    />
  </svg>
);

Validated.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

export default Validated;
