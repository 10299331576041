export const fieldTypes = {
  autocomplete: 'Autocomplete',
  currency: 'Currency',
  text: 'Text',
  checkbox: 'Checkbox',
  radio: 'Radio',
};

const cityField = {
  type: fieldTypes.autocomplete,
  name: 'city',
  label: 'app.form.city.label',
  placeholder: 'app.form.city.placeholder',
  required: true,
  additionalValidation: true,
};

const minPriceField = {
  type: fieldTypes.currency,
  name: 'minPrice',
  label: 'app.form.minPrice.label',
  placeholder: 'app.form.minPrice.placeholder',
  required: false,
};

const maxPriceField = {
  type: fieldTypes.currency,
  name: 'maxPrice',
  label: 'app.form.maxPrice.label',
  placeholder: 'app.form.maxPrice.placeholder',
  required: true,
};

const minSizeField = {
  type: fieldTypes.text,
  name: 'minSize',
  label: 'app.form.minSize.label',
  placeholder: 'app.form.minSize.placeholder',
  required: true,
  suggestions: [
    { label: '50+ m2', value: 50 },
    { label: '70+ m2', value: 70 },
    { label: '90+ m2', value: 90 },
  ],
};

export const buyFlow = {
  steps: [
    {
      icon: 'location',
      title: 'app.buy.city.title',
      text: 'app.buy.city.text',
      link: 'app.buy.city.link',
      fields: [cityField],
    },
    {
      icon: 'money',
      title: 'app.buy.price.title',
      text: 'app.buy.price.text',
      fields: [
        {
          type: fieldTypes.currency,
          name: 'minPrice',
          label: 'app.form.minPrice.label',
          placeholder: 'app.form.minPrice.placeholder',
          required: false,
        },
        {
          type: fieldTypes.currency,
          name: 'maxPrice',
          label: 'app.form.maxPrice.label',
          placeholder: 'app.form.maxPrice.placeholder',
          required: true,
        },
      ],
    },
    {
      icon: 'parcel',
      title: 'app.buy.size.title',
      fields: [minSizeField],
    },
  ],
};

export const rentFlow = {
  steps: [
    {
      icon: 'location',
      title: 'app.rent.city.title',
      text: 'app.rent.city.text',
      link: 'app.rent.city.link',
      fields: [cityField],
    },
    {
      icon: 'money',
      title: 'app.rent.price.title',
      text: 'app.rent.price.text',
      fields: [
        {
          type: fieldTypes.currency,
          name: 'minPrice',
          label: 'app.form.minPrice.label',
          placeholder: 'app.rent.price.minPrice.placeholder',
          required: false,
        },
        {
          type: fieldTypes.currency,
          name: 'maxPrice',
          label: 'app.form.maxPrice.label',
          placeholder: 'app.rent.price.maxPrice.placeholder',
          required: true,
        },
      ],
    },
    {
      icon: 'parcel',
      title: 'app.rent.size.title',
      fields: [minSizeField],
    },
  ],
};

export const buyFiltersOverview = [
  {
    name: 'rooms',
    icon: 'door',
    label: 'app.filter.rooms.label',
    title: 'app.filter.rooms.title',
    fields: [
      {
        type: fieldTypes.radio,
        name: 'rooms',
        required: true,
        options: [
          { label: '1+', value: 1 },
          { label: '2+', value: 2 },
          { label: '3+', value: 3 },
          { label: '4+', value: 4 },
          { label: '5+', value: 5 },
        ],
      },
    ],
  },
  {
    name: 'bedrooms',
    icon: 'bed',
    label: 'app.filter.bedrooms.label',
    title: 'app.filter.bedrooms.title',
    fields: [
      {
        type: fieldTypes.radio,
        name: 'bedrooms',
        required: true,
        options: [
          { label: '1+', value: 1 },
          { label: '2+', value: 2 },
          { label: '3+', value: 3 },
          { label: '4+', value: 4 },
          { label: '5+', value: 5 },
        ],
      },
    ],
  },
  {
    name: 'constructionYear',
    icon: 'bricks',
    label: 'app.filter.constructionYear.label',
    title: 'app.filter.constructionYear.title',
    fields: [
      {
        type: fieldTypes.radio,
        name: 'constructionYear',
        required: true,
        options: [
          { label: '1906+', value: 1906 },
          { label: '1920+', value: 1920 },
          { label: '1940+', value: 1940 },
          { label: '1960+', value: 1960 },
          { label: '1980+', value: 1980 },
          { label: '2000+', value: 2000 },
          { label: '2020+', value: 2020 },
        ],
      },
    ],
  },
  {
    name: 'range',
    icon: 'distance',
    label: 'app.filter.range.label',
    title: 'app.filter.range.title',
    fields: [
      {
        type: fieldTypes.radio,
        name: 'range',
        required: true,
        options: [
          { label: '2+ km', value: 2 },
          { label: '5+ km', value: 5 },
          { label: '10+ km', value: 10 },
          { label: '15+ km', value: 15 },
          { label: '30+ km', value: 30 },
        ],
      },
    ],
  },
  {
    name: 'location',
    icon: 'location',
    label: 'app.filter.city.label',
    title: 'app.filter.city.title',
    fields: [cityField],
  },
  {
    name: 'price',
    icon: 'money',
    label: 'app.filter.price.label',
    title: 'app.filter.price.title',
    fields: [minPriceField, maxPriceField],
  },
  {
    name: 'size',
    icon: 'parcel',
    label: 'app.filter.size.label',
    title: 'app.filter.size.title',
    fields: [minSizeField],
  },
];

export const rentFiltersOverview = [
  {
    name: 'rooms',
    icon: 'door',
    label: 'app.filter.rooms.label',
    title: 'app.filter.rooms.title',
    fields: [
      {
        type: fieldTypes.radio,
        name: 'rooms',
        required: true,
        options: [
          { label: '1+', value: 1 },
          { label: '2+', value: 2 },
          { label: '3+', value: 3 },
          { label: '4+', value: 4 },
          { label: '5+', value: 5 },
        ],
      },
    ],
  },
  {
    name: 'bedrooms',
    icon: 'bed',
    label: 'app.filter.bedrooms.label',
    title: 'app.filter.bedrooms.title',
    fields: [
      {
        type: fieldTypes.radio,
        name: 'bedrooms',
        required: true,
        options: [
          { label: '1+', value: 1 },
          { label: '2+', value: 2 },
          { label: '3+', value: 3 },
          { label: '4+', value: 4 },
          { label: '5+', value: 5 },
        ],
      },
    ],
  },
  {
    name: 'constructionYear',
    icon: 'bricks',
    label: 'app.filter.constructionYear.label',
    title: 'app.filter.constructionYear.title',
    fields: [
      {
        type: fieldTypes.radio,
        name: 'constructionYear',
        required: true,
        options: [
          { label: '1906+', value: 1906 },
          { label: '1920+', value: 1920 },
          { label: '1940+', value: 1940 },
          { label: '1960+', value: 1960 },
          { label: '1980+', value: 1980 },
          { label: '2000+', value: 2000 },
          { label: '2020+', value: 2020 },
        ],
      },
    ],
  },
  {
    name: 'range',
    icon: 'distance',
    label: 'app.filter.range.label',
    title: 'app.filter.range.title',
    fields: [
      {
        type: fieldTypes.radio,
        name: 'range',
        required: true,
        options: [
          { label: '2+ km', value: 2 },
          { label: '5+ km', value: 5 },
          { label: '10+ km', value: 10 },
          { label: '15+ km', value: 15 },
          { label: '30+ km', value: 30 },
        ],
      },
    ],
  },
  {
    name: 'location',
    icon: 'location',
    label: 'app.filter.city.label',
    title: 'app.filter.city.title',
    fields: [cityField],
  },
  {
    name: 'price',
    icon: 'money',
    label: 'app.filter.price.label',
    title: 'app.filter.price.title',
    fields: [minPriceField, maxPriceField],
  },
  {
    name: 'size',
    icon: 'parcel',
    label: 'app.filter.size.label',
    title: 'app.filter.size.title',
    fields: [minSizeField],
  },
];
