import React, { useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

// Constants
import paths from '../constants/paths';

// Providers
import { I18nContext } from '../providers/I18nContextProvider';

// Components
import Seo from '../components/Seo';
import Page from '../components/layouts/Page';
import Container from '../components/layouts/Container';
import Button from '../components/elements/Button';

// Styling
import sizes from '../styles/sizes';

const Styled = {
  Title: styled.h1`
    margin-bottom: ${sizes.space * 2}px;
  `,
};

const NotFound = () => {
  const { translate } = useContext(I18nContext);
  const router = useHistory();
  return (
    <Page>
      <Seo title="404" />
      <Container size="small">
        <Styled.Title>{translate('app.error.404.title')}</Styled.Title>
        <Button label={translate('app.error.404.text')} onClick={() => router.push(paths.home)} />
      </Container>
    </Page>
  );
};

export default NotFound;
