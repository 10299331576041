import React from 'react';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';

const Chevron = ({ width, className, color }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 15 9"
    className={className}
  >
    <path d="M14.834.166l-7 5.334-7-5.334v3l7 5.334 7-5.334v-3z" fill={color} />
  </svg>
);

Chevron.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  color: PropTypes.string,
};

Chevron.defaultProps = {
  color: colors.black,
};

export default Chevron;
