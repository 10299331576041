import React from 'react';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';

const Celebrate = ({ width, className }) => (
  <svg
    className={className}
    style={{
      width: `${width}px`,
    }}
    fill="none"
    viewBox="0 0 160 160"
  >
    <g clipPath="url(#prefix__clip0)">
      <path d="M66 126l-46 16 16-46 30 30z" fill={colors.primary} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.16 92.332l34.51 34.511-52.916 18.406L35.16 92.332zm1.682 7.34l-13.594 39.083L62.33 125.16 36.84 99.671z"
        fill={colors.primary}
      />
      <path d="M88 118L42 74l-8 18 32 34 22-8z" fill={colors.white} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.328 70.59l50.38 48.19-26.262 9.55-33.813-35.927 9.695-21.813zm1.345 6.822l-6.305 14.186 30.186 32.073 17.738-6.45-41.619-39.81z"
        fill={colors.white}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.625 64l-28.313 84.94 84.94-28.313L40.625 64zm3.496 14.81l-19.16 57.481 57.481-19.16-38.32-38.321z"
        fill={colors.black}
      />
      <path
        d="M140 112c0 8.837-7.163 16-16 16s-16-7.163-16-16 7.163-16 16-16 16 7.163 16 16z"
        fill="#FE8F50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124 120a8 8 0 100-16 8 8 0 000 16zm0 8c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16z"
        fill={colors.black}
      />
      <path
        d="M152 60c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12z"
        fill={colors.white}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140 64a4 4 0 100-8 4 4 0 000 8zm0 8c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12z"
        fill={colors.black}
      />
      <path
        d="M112 20c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12z"
        fill="#FE8F50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100 24a4 4 0 100-8 4 4 0 000 8zm0 8c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12zM77.817 20.809c8.393 26.854.747 53.008-4.24 62.981l-7.155-3.577c4.346-8.693 11.368-32.673 3.76-57.018l7.635-2.386zm63.374 62.932c-26.854-8.392-53.008-.747-62.982 4.24l3.578 7.156c8.693-4.347 32.673-11.368 57.018-3.76l2.386-7.636zm9.638-68.912l-56 56-5.657-5.657 56-56 5.657 5.657z"
        fill={colors.black}
      />
      <path
        d="M60 40c0 8.837-7.163 16-16 16s-16-7.163-16-16 7.163-16 16-16 16 7.163 16 16z"
        fill={colors.white}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44 48a8 8 0 100-16 8 8 0 000 16zm0 8c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16zm21.172 74.829l-32-32 5.657-5.657 32 32-5.657 5.657z"
        fill={colors.black}
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill={colors.white} d="M0 0h160v160H0z" />
      </clipPath>
    </defs>
  </svg>
);

Celebrate.propTypes = {
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

export default Celebrate;
