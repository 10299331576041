import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';
import textStyles from '../../styles/textStyles';

const Styled = {
  Container: styled.div`
    ${textStyles.bodySmall};
    margin-top: 4px;
    color: ${({ type }) => (type === 'error' ? colors.error : colors.warning)};
  `,
};

const Error = ({ type, label }) => <Styled.Container type={type}>{label}</Styled.Container>;

Error.propTypes = {
  type: PropTypes.oneOf(['error', 'warning']),
  label: PropTypes.string.isRequired,
};

Error.defaultProps = {
  type: 'error',
};

export default Error;
