import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Constants
import paths from './constants/paths';

// Pages
import Home from './pages/Index';

import Questionnaire from './pages/questionnaire/Index';
import Buy from './pages/questionnaire/Buy';
import Rent from './pages/questionnaire/Rent';
import Overview from './pages/questionnaire/Overview';
import Edit from './pages/questionnaire/Edit';
import Register from './pages/questionnaire/Register';
import Success from './pages/questionnaire/Success';
import Confirm from './pages/questionnaire/Confirm';
import Unsubscribe from './pages/Unsubscribe';

import LisitingDetail from './pages/ListingDetail';

import Faq from './pages/Faq';
import Contact from './pages/Contact';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';

import NoMatch from './pages/404';

const RouterComponent = () => (
  <Router>
    <Switch>
      <Route exact path={paths.home} component={Home} />

      <Route exact path={paths.questionnaire} component={Questionnaire} />
      <Route exact path={paths.questionnaireBuy} component={Buy} />
      <Route exact path={paths.questionnaireRent} component={Rent} />
      <Route exact path={paths.questionnaireOverview} component={Overview} />
      <Route exact path={paths.questionnaireEdit(':name')} component={Edit} />
      <Route exact path={paths.questionnaireRegister} component={Register} />
      <Route exact path={paths.questionnaireSuccess} component={Success} />
      <Route exact path={paths.questionnaireConfirm(':id', ':token')} component={Confirm} />
      <Route exact path={paths.unsubscribe(':id', ':token')} component={Unsubscribe} />

      <Route exact path={paths.listingDetail(':id')} component={LisitingDetail} />

      <Route path={paths.faq} component={Faq} />
      <Route path={paths.contact} component={Contact} />
      <Route path={paths.disclaimer} component={Terms} />
      <Route path={paths.privacy} component={Privacy} />

      <Route path="*" component={NoMatch} />
    </Switch>
  </Router>
);

export default RouterComponent;
