import React from 'react';
import PropTypes from 'prop-types';

const Arrow = ({ width, color, className }) => (
  <svg
    style={{
      width: `${width}px`,
    }}
    viewBox="0 0 27 19"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.883 10l-7.527 6.235L16 17l9-7.521L16 2l-.645.764L22.884 9H1v1h21.883z"
      fill={color}
      stroke={color}
      strokeWidth={2}
    />
  </svg>
);

Arrow.propTypes = {
  width: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default Arrow;
