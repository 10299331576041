// actions
import { LISTING_FETCH_SUCCESS, LISTING_FETCH_FAIL } from '../actions/listing';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case LISTING_FETCH_SUCCESS: {
      return {
        ...state,
        status: 'success',
        data: {
          ...actions.payload.data,
        },
      };
    }

    case LISTING_FETCH_FAIL: {
      return {
        status: 'failed',
      };
    }

    default:
      return state;
  }
};
