import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Styles
import colors from '../../styles/colors';
import textStyles from '../../styles/textStyles';
import sizes from '../../styles/sizes';

const Styled = {
  Component: styled.button`
    ${textStyles.bodySmall};
    cursor: pointer;
    padding: ${sizes.space}px ${sizes.space * 2}px;
    border: 2px solid ${colors.black};
    border-radius: ${sizes.space * 5}px;
    outline: none;
    background-color: transparent;
    font-weight: 700;

    :hover,
    :focus {
      background-color: ${colors.primary};
    }
  `,
};

const SuggestionButton = ({ label, onClick }) => (
  <Styled.Component type="button" onClick={onClick}>
    {label}
  </Styled.Component>
);

SuggestionButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SuggestionButton;
