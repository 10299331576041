import React from 'react';
import PropTypes from 'prop-types';

const Shape = ({ className }) => (
  <svg viewBox="0 0 103 120" className={className}>
    <path
      d="M1.07312 55.6694C4.45215 49.0117 16.5371 40.7897 27.9861 31.2844C39.7133 21.9796 50.7249 11.3914 58.5961 4.61328C66.8648 -1.76373 71.9929 -2.08458 79.5858 6.97959C86.8209 15.763 95.1691 34.1721 100.019 58.2764C104.511 82.2202 103.279 104.399 96.5207 113.824C89.7626 123.891 79.7846 120.763 63.605 110.335C47.7434 99.8673 28.9402 87.033 16.2589 78.1293C3.33906 68.8245 -2.62393 62.7282 1.07312 55.6694Z"
      fill="#53D28D"
    />
  </svg>
);

Shape.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Shape;
